import { useMemo } from 'react';
import { useQueryWithStore } from 'react-admin';

export const useLanguageList = () => {
  const { data, loading } = useQueryWithStore({
    type: 'getCustomList',
    resource: 'translation/get-language-codes',
  });

  return useMemo(
    () => ({
      loading,
      data: data?.data,
    }),
    [data, loading],
  );
};
