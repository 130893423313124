/* eslint-disable */
import React, { memo, useState, useEffect } from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const { RangePicker } = DatePicker;

const RangePickerReport = (props) => {
  const {
    translate,
    disabled = false,
    allowClear = true,
    value,
    bordered,
    format,
    locale,
    onChange,
    reportServiceVersion,
    disabledDate: defaultDisabledDate,
    className,
    source,
    showTime,
    ...otherProps
  } = props;

  const [dates, setDates] = useState(null);

  const getNumberOfDateLimit = () => {
    if (reportServiceVersion === 0) {
      return 31; // Limit 1 month with the reports using the 1st service version
    }
    // Set unlimited time range filter with the reports using the rest service versions
    return undefined; // Unlimited
  };

  const disabledDate = (current) => {
    if (!dates || !current) {
      return false;
    } else if (defaultDisabledDate !== undefined) {
      if (typeof defaultDisabledDate === 'function') {
        return defaultDisabledDate(current, dates);
      } else {
        return !!defaultDisabledDate;
      }
    } else if (current.isAfter(moment().endOf('day'))) {
      return true;
    } else {
      const startDate = dates[0] && moment(dates[0].format()).startOf('day');
      const endDate = dates[1] && moment(dates[1].format()).startOf('day');

      const currentDate = moment(current.format()).startOf('day');

      const numberOfDateLimit = getNumberOfDateLimit();

      const tooLate = startDate ? currentDate.diff(startDate, 'days') >= numberOfDateLimit : false;

      const tooEarly = endDate ? endDate.diff(currentDate, 'days') >= numberOfDateLimit : false;

      return !!tooEarly || !!tooLate;
    }
  };

  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  useEffect(() => {
    const dateRangePickerEls = Array.from(document.getElementsByClassName(source));

    if (!dateRangePickerEls?.length) return;

    let inputEls = [];

    try {
      inputEls = Array.from(dateRangePickerEls?.[0]?.getElementsByTagName('input'));
    } catch (error) {
      console.log(error);
    }

    const startDateInput = inputEls[0];
    const endDateInput = inputEls[1];

    const handleStartInputFocus = (e) => {
      if (endDateInput?.value) {
        const newDate = dates || [null, null];
        newDate[1] = moment(endDateInput.value, format);
        setDates(newDate);
      }
    };

    const handleEndInputFocus = (e) => {
      if (startDateInput?.value) {
        const newDate = dates || [null, null];
        newDate[0] = moment(startDateInput.value, format);
        setDates(newDate);
      }
    };

    startDateInput?.addEventListener('focus', handleStartInputFocus);
    endDateInput?.addEventListener('focus', handleEndInputFocus);

    return function cleanUp() {
      startDateInput?.removeEventListener('focus', handleStartInputFocus);
      endDateInput?.removeEventListener('focus', handleEndInputFocus);
    };
  }, [dates, source]);

  return (
    <>
      <RangePicker
        style={{
          maxWidth: '350px',
        }}
        allowClear={allowClear}
        value={dates || value}
        disabled={disabled}
        bordered={bordered}
        format={format}
        locale={locale}
        disabledDate={disabledDate}
        onChange={onChange}
        onCalendarChange={(val) => setDates(val)}
        onOpenChange={onOpenChange}
        {...otherProps}
        className={clsx('date-range-picker', source, className)}
        showTime={showTime ?? { defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('00:00', 'mm:ss')] }}
      />
    </>
  );
};

RangePickerReport.propTypes = {
  resource: PropTypes.string,
  translate: PropTypes.func,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  showTime: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  allowClear: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.any),
  bordered: PropTypes.bool,
  format: PropTypes.string,
  locale: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  reportServiceVersion: PropTypes.number,
  className: PropTypes.string,
  source: PropTypes.string,
};

RangePickerReport.defaultProps = {
  translate: (f) => f,
  disabled: false,
  showTime: false,
  allowClear: false,
  value: [],
  bordered: false,
  format: null,
  locale: 'en',
  onChange: (f) => f,
  resource: '',
  reportServiceVersion: 0,
  className: '',
  source: '',
};

export default memo(RangePickerReport);
