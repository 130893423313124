import React from 'react';
import { Tab, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  tabRoot: {
    "&[class*='selected'] $tabWrapper": {
      color: theme.palette.primary.main,
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      fontWeight: 700,
    },
    padding: 0,
    maxWidth: 'unset',
  },
  tabWrapper: {
    textTransform: 'capitalize',
    color: 'black',
    fontWeight: 600,
    padding: '10px 32px',
  },
}));

const MuiTab = (props) => {
  const classes = useStyles();

  return (
    <Tab
      {...props}
      classes={{
        root: clsx(classes.tabRoot, props.classes?.root),
        wrapper: clsx(classes.tabWrapper, props.classes?.wrapper),
      }}
    />
  );
};

export default MuiTab;
