export const REWARD_TYPES = {
  FREE_SPIN: 'FREE_SPIN',
  CASH_VOUCHER: 'CASH_VOUCHER',
  RUBY_CARD: 'RUBY_CARD',
  GOLD_CARD: 'GOLD_CARD',
};

export const REWARD_EXPIRY_UNITS = {
  HOURS: 'HOURS',
  DAYS: 'DAYS',
};

export const REWARD_ACTION_TYPES = {
  CREATE: 'CREATED',
  UPDATE: 'UPDATED',
};
