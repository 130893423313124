import { Box, Chip, useTheme } from '@material-ui/core';
import React from 'react';
import { FunctionField, useTranslate } from 'react-admin';
import { CHECK_IN_STATUS } from '../../../../constant/checkIn';

const CheckInStatus = () => {
  const t = useTranslate();
  const theme = useTheme();

  return (
    <FunctionField
      source="status"
      render={(record) => (
        <Box
          component={Chip}
          sx={{
            "&[class*='Chip']": {
              borderRadius: 6,
              padding: '3px 12px',
              backgroundColor: {
                [CHECK_IN_STATUS.ACTIVE]: theme.palette.success.main,
                [CHECK_IN_STATUS.EXPIRED]: theme.palette.error.light,
                [CHECK_IN_STATUS.CANCELED]: theme.palette.grey[400],
              }[record.status],
              color: 'white',
              '& span': {
                padding: '0 !important', // To override default styles
              },
            },
          }}
          label={t(`resources.check-in.status.${record.status}`)}
        />
      )}
    />
  );
};

export default CheckInStatus;
