import React from 'react';
import { Box } from '@material-ui/core';

const Fieldset = ({
  title,
  color = 'rgba(0,0,0,0.6)',
  titleSize = '1rem',
  borderWidth = 1,
  borderRadius = 2,
  children,
  sx = {},
  titleSx = {},
  ...props
}) => (
  <Box
    component="fieldset"
    sx={{
      borderColor: color,
      borderWidth,
      borderRadius,
      position: 'relative',
      ...sx,
    }}
    {...props}
  >
    {title && (
      <Box
        component="legend"
        px={2}
        sx={{
          color,
          fontSize: titleSize,
          position: 'absolute',
          top: 0,
          left: 0,
          width: 'fit-content',
          transformOrigin: 'center left',
          transform: 'translate(9px, -50%) scale(0.75)',
          bgcolor: 'white',
          zIndex: 2,
          ...titleSx,
        }}
      >
        {title}
      </Box>
    )}
    {children}
  </Box>
);

export default Fieldset;
