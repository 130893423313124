import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDataProvider, useListContext } from 'react-admin';
import InfinitePagination from '../../../../base/components/ra/list/InfinitePagination';
import resourceSlug from '../../../../constant/resource-slug';
import { sanitizeObject } from '../../../../services/util';
import { cacheRewardsByPage, updateCurrentRewardPage } from '../../../../services/redux/reward/reward.action';

const RewardPagination = () => {
  const { rewardList, currentRewardPage } = useSelector(({ reward }) => reward);
  const dispatch = useDispatch();
  const dataProvider = useDataProvider();
  const { filterValues, currentSort, perPage } = useListContext();

  const disableNext = useMemo(() => !rewardList?.[currentRewardPage]?.nextPagination, [currentRewardPage, rewardList]);

  const handlePageChange = useCallback(
    async (newPage) => {
      try {
        if (!rewardList[newPage]) {
          const nextPagination = rewardList?.[newPage - 1]?.nextPagination;
          const data = await dataProvider.getList(resourceSlug.REWARD, {
            pagination: {
              perPage,
              cachedPage: newPage,
            },
            sort: currentSort,
            filter: {
              ...filterValues,
              q: sanitizeObject({
                nextPagination: JSON.stringify(nextPagination),
              }),
            },
          });

          dispatch(
            cacheRewardsByPage({
              data,
              page: newPage,
            }),
          );
        }
        dispatch(updateCurrentRewardPage(newPage));
      } catch (error) {
        // Do nothing
      }
    },
    [rewardList, currentRewardPage],
  );

  return (
    <InfinitePagination
      disableNext={disableNext}
      disablePrev={currentRewardPage === 1}
      onNextPage={() => {
        handlePageChange(currentRewardPage + 1);
      }}
      onPrevPage={() => {
        handlePageChange(currentRewardPage - 1);
      }}
      currentPage={currentRewardPage}
    />
  );
};

export default RewardPagination;
