import { useEffect } from 'react';
import { useTranslate } from 'ra-core';
import { useNotify, useUpdate } from 'react-admin';
import { useHistory } from 'react-router-dom';
import useError from './useError';

const useUpdateCustom = ({ resource, resourceTranslated, redirectTo = 'show', successMessage, onSuccess, onError }) => {
  const translate = useTranslate();
  const notify = useNotify();
  const history = useHistory();
  const { notifyError } = useError();

  const handleUpdateSuccess = (response) => {
    notify(
      successMessage
        ? translate(successMessage)
        : translate('ra.notification.updated', {
            smart_name: translate(resourceTranslated),
          }),
      'success',
    );

    if (redirectTo === 'show') {
      history.replace(`/${resource}/${response?.data.id}/${redirectTo}`);
    } else if (redirectTo === 'list') {
      history.replace(`/${resource}`);
    }
  };

  const [update, { loading: updateLoading, error }] = useUpdate(undefined, undefined, undefined, undefined, {
    onSuccess: onSuccess || handleUpdateSuccess,
  });

  useEffect(() => {
    if (!updateLoading && error) {
      if (onError) {
        onError?.(error);
      } else {
        notifyError(error);
      }
    }
  }, [updateLoading, error, onError]);

  return {
    update,
    updateLoading,
    error,
  };
};

export default useUpdateCustom;
