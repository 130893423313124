/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { Box, Button, useTheme } from '@material-ui/core';
import { ReferenceField, useTranslate, TextField } from 'react-admin';
import moment from 'moment';
import get from 'lodash/get';
import WealthListGuesser from '../../base/components/guesser/wealth-list.guesser';
import { ActionList } from './components/action.list';
import ButtonUnlock from './components/ButtonUnlock';
import resourceSlug from '../../constant/resource-slug';
import { FILTER_PREFIX_ON_VALUE, SERVER_DATETIME_FORMAT } from '../../constant';

const PlayerActions = props => {
  const translate = useTranslate();
  const muiTheme = useTheme();

  const playerId = get(props, 'record.id');
  const nativeId = get(props, 'record.nativeId');

  const wagerFilterDefaultValues = useMemo(() => {
    const today = moment().add(1, 'day').startOf('day').format(SERVER_DATETIME_FORMAT);
    const yesterday = moment().subtract(1, 'days').startOf('day')
      .format(SERVER_DATETIME_FORMAT);

    return {
      created: `${FILTER_PREFIX_ON_VALUE.BETWEEN}${yesterday},${today}`,
    };
  }, []);

  return playerId ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <a
        href={`#/wager?filter=${JSON.stringify({
          ...wagerFilterDefaultValues,
          'playerId||$eq': playerId,
        })}&limit=25&order=ASC&page=1&perPage=25&sort=created`}
      >
        <Button
          color="secondary"
          variant="contained"
          size="small"
          style={{
            background: muiTheme.palette.primary.light,
            color: muiTheme.palette.secondary.main,
          }}
        >
          {translate('ra.action.view_wager')}
        </Button>
      </a>
      <ButtonUnlock
        playerId={playerId}
        nativeId={nativeId}
        showIcon={false}
        isLocked={get(props, 'record.lockSpinning')}
        isSecondary
      />
    </Box>
  ) : null;
};

function PlayerList(props) {
  return (
    <>
      <WealthListGuesser
        {...props}
        rowAction={<PlayerActions label="ra.field.action" />}
        actions={<ActionList />}
        rowClick=""
        excludeFields={['created', 'updated', 'wallet']}
      >
        <ReferenceField
          source="wallet.id"
          reference={resourceSlug.WALLET}
          label="resources.player.fields.wallet"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
      </WealthListGuesser>
    </>
  );
}

export default PlayerList;
