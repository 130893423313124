import React from 'react';
import { Tabs, Tab as MuiTab, Box, makeStyles } from '@material-ui/core';
import { useRedirect, useTranslate } from 'react-admin';
import { useParams } from 'react-router-dom';
import resourceSlug from '../../../../constant/resource-slug';

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    borderBottom: `2px solid ${theme.palette.grey[200]}`,
  },
  tabRoot: {
    "&[class*='selected'] $tabWrapper": {
      color: theme.palette.primary.main,
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      fontWeight: 700,
    },
    padding: 0,
  },
  tabWrapper: {
    textTransform: 'capitalize',
    color: 'black',
    fontWeight: 600,
    padding: '10px 32px',
  },
}));

const Tab = (props) => {
  const classes = useStyles();

  return (
    <MuiTab
      classes={{
        root: classes.tabRoot,
        wrapper: classes.tabWrapper,
      }}
      {...props}
    />
  );
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
    value: index,
  };
}

export const CHECK_IN_TABS = {
  GENERAL_INFO: 'show',
  PLAYER_REWARDS: 'player-rewards',
  CHANGE_LOG: 'change-log',
};

const CheckInDetailsTabs = ({ currentTab, children }) => {
  const t = useTranslate();
  const classes = useStyles();
  const redirect = useRedirect();
  const { id } = useParams();

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >
        <Tabs
          value={currentTab}
          classes={{
            flexContainer: classes.flexContainer,
          }}
        >
          <Tab
            label={t('resources.check-in.general-info')}
            onClick={() => redirect(`/${resourceSlug.CHECK_IN}/${id}/show`)}
            {...a11yProps(CHECK_IN_TABS.GENERAL_INFO)}
          />
          <Tab
            label={t('resources.check-in.player-rewards')}
            onClick={() => redirect(`/${resourceSlug.CHECK_IN}/${id}/player-rewards`)}
            {...a11yProps(CHECK_IN_TABS.PLAYER_REWARDS)}
          />
          <Tab
            label={t('resources.check-in.change-log')}
            onClick={() => redirect(`/${resourceSlug.CHECK_IN}/${id}/change-log`)}
            {...a11yProps(CHECK_IN_TABS.CHANGE_LOG)}
          />
        </Tabs>
      </Box>
      {children}
    </Box>
  );
};

export default CheckInDetailsTabs;
