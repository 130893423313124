import { Box, Chip, useTheme } from '@material-ui/core';
import React from 'react';
import { useTranslate } from 'react-admin';
import { PLAYER_REWARD_STATUS } from '../../../../../constant/checkIn';

const PlayerRewardStatus = ({ status }) => {
  const t = useTranslate();
  const theme = useTheme();

  return (
    <Box
      component={Chip}
      sx={{
        "&[class*='Chip']": {
          borderRadius: 6,
          padding: '3px 12px',
          backgroundColor: {
            [PLAYER_REWARD_STATUS.COMPLETED]: theme.palette.success.main,
            [PLAYER_REWARD_STATUS.CLAIMED]: theme.palette.primary.main,
            [PLAYER_REWARD_STATUS.ISSUED]: theme.palette.secondary.main,
            [PLAYER_REWARD_STATUS.EXPIRED]: theme.palette.grey[800],
          }[status],
          color: 'white',
          '& span': {
            padding: '0 !important', // To override default styles
          },
        },
      }}
      label={t(`resources.check-in.player-reward-status.${status}`)}
    />
  );
};

export default PlayerRewardStatus;
