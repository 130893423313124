import React, { useMemo } from 'react';
import { useTranslate } from 'react-admin';
import { useParams } from 'react-router-dom';
import { CONVERSION_TRANSACTION_STATUS, CONVERSION_TRANSFER_TYPE } from '../../../../../constant/player';
import CustomizedFilterWrapper from '../../../../../base/components/customized/CustomizedFilterWrapper';
import { SelectInput, TextInput } from '../../../../../base/components/ra/inputs';
import DateRangePickerInput from '../../../../../base/components/guesser/date-range-input';
import resourceSlug from '../../../../../constant/resource-slug';

const ConversionHistoryFilters = () => {
  const t = useTranslate();
  const { id } = useParams();

  const statusChoices = useMemo(
    () =>
      Object.values(CONVERSION_TRANSACTION_STATUS).map((status) => ({
        id: status,
        name: t(`resources.player.transaction-status.${status}`),
      })),
    [t],
  );

  const transferTypeChoices = useMemo(
    () =>
      Object.values(CONVERSION_TRANSFER_TYPE).map((type) => ({
        id: type,
        name: t(`resources.player.transfer-type.${type}`),
      })),
    [t],
  );

  return (
    <CustomizedFilterWrapper>
      <DateRangeInput
        source="created"
        label={t('common.field.created')}
        resource={`${resourceSlug.PLAYER}/${id}/wallet-conversion-history`}
      />
      <TextInput source="transactionId||$eq" label="common.fields.transactionId" resettable />
      <SelectInput
        source="transferType"
        label="resources.player.fields.transfer-type"
        choices={transferTypeChoices}
        resettable
      />
      <SelectInput source="status" label="common.fields.status" choices={statusChoices} resettable />
    </CustomizedFilterWrapper>
  );
};

const DateRangeInput = (props) => <DateRangePickerInput prefix ranges={[]} {...props} />;

export default ConversionHistoryFilters;
