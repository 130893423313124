import React from 'react';
import { TextField, useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import CustomizedList from '../../../../../base/components/customized/CustomizedList';
import CustomLinkField from '../../../../../base/components/ra/fields/customLink.field';
import resourceSlug from '../../../../../constant/resource-slug';

const BrandList = ({ excludedGroupIds, groupIds, excludedBrandIds, brandIds }) => {
  const t = useTranslate();

  return (
    <Box px={12.5} pb={4} mt={-6}>
      <Box
        component={Typography}
        variant="h4"
        sx={{
          margin: '10px 0 !important',
        }}
      >
        {t('resources.brand.name')}
      </Box>
      <Box
        component={CustomizedList}
        sx={{
          "& [class*='MuiTableContainer']": {
            maxHeight: '300px !important',
          },
        }}
        resource="brand"
        basePath="/brand"
        filter={{
          'group.id||$notin': excludedGroupIds,
          'group.id||$in': groupIds,
          'id||$notin': excludedBrandIds,
          'id||$in': brandIds,
        }}
        disabledAutoRefetch
        hasList
        actions={null}
        filters={null}
        exporter={false}
        paginationProps={{
          rowsPerPageOptions: [25],
        }}
      >
        <CustomLinkField
          source="name"
          sortable={false}
          label="resources.brand.name"
          href={(record) => `/#/${resourceSlug.BRAND}/${record.id}/show`}
          title={(record) => record.name}
        />
        <TextField source="code" sortable={false} />
        <CustomLinkField
          source="group"
          sortable={false}
          label="resources.group.name"
          href={(record) => `/#/${resourceSlug.GROUP}/${record.group.id}/show`}
          title={(record) => record.group.name}
        />
      </Box>
    </Box>
  );
};

BrandList.propTypes = {
  excludedGroupIds: PropTypes.arrayOf(PropTypes.string),
  groupIds: PropTypes.arrayOf(PropTypes.string),
  excludedBrandIds: PropTypes.arrayOf(PropTypes.string),
  brandIds: PropTypes.arrayOf(PropTypes.string),
};

export default BrandList;
