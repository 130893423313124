/* eslint-disable import/no-cycle */

import React, { useContext, useEffect, useMemo } from 'react';
import { FunctionField, ReferenceField, SelectField, TextField, useTranslate } from 'react-admin';
import { useSelector } from 'react-redux';
import { keyBy } from 'lodash';
import { Route } from 'react-router';
import { useLocation } from 'react-router-dom';
import CustomizedList from '../../base/components/customized/CustomizedList';
import resourceSlug from '../../constant/resource-slug';
import CustomLinkField from '../../base/components/ra/fields/customLink.field';
import { transformIdValue } from '../../services/util/stringUtil';
import RewardFilterWrapper from './components/RewardList/RewardFilters';
import RewardPagination from './components/RewardList/RewardPagination';
import RewardActionWrapper from './components/RewardList/RewardActions';
import { REWARD_TYPES } from '../../constant/reward';
import RewardDetailsDrawer from './components/RewardDetailsDrawer';
import { NavigationContext } from '../../App';
import ActiveLabel from '../../base/components/ActiveLabel';
import RewardCreateUpdateDrawer from './components/RewardCreateUpdateDrawer';

const RewardList = (props) => {
  const t = useTranslate();
  const { rewardList, currentRewardPage } = useSelector(({ reward }) => reward);
  const { search } = useLocation();

  const rewards = useMemo(() => rewardList[currentRewardPage]?.data || [], [rewardList, currentRewardPage]);

  const { setNavigationData } = useContext(NavigationContext);

  useEffect(() => {
    setNavigationData({
      pageTitleInfo: {
        label: t('resources.reward.name'),
      },
    });

    return () => {
      setNavigationData({});
    };
  }, [t]);

  return (
    <>
      <CustomizedList
        {...props}
        filters={<RewardFilterWrapper />}
        actions={<RewardActionWrapper />}
        disabledAutoRefetch
        hasList
        exporter={false}
        controllerProps={{
          data: keyBy(rewards, 'id'),
          ids: rewards?.map(({ id }) => id),
        }}
        pagination={<RewardPagination />}
        rowClick={(id) => `/${resourceSlug.REWARD}/${id}/details${search}`}
        syncWithLocation
      >
        <CustomLinkField
          source="id"
          label="common.fields.id"
          href={(record) => `/#/${resourceSlug.REWARD}/${record.id}/details${search}`}
          title={(record) => transformIdValue(record.id)}
        />
        <TextField source="rewardName" label="resources.reward.fields.name" sortable={false} />
        <SelectField
          source="rewardType"
          label="resources.reward.fields.reward-type"
          choices={Object.values(REWARD_TYPES).map((type) => ({
            id: type,
            name: t(`resources.reward.reward-type.${type}`),
          }))}
          sortable={false}
        />
        <ReferenceField
          label="resources.reward.fields.currency-code"
          sortable={false}
          reference={resourceSlug.CURRENCY}
          source="currencyId"
          link={false}
        >
          <TextField source="code" />
        </ReferenceField>
        <FunctionField
          label="resources.reward.fields.expiry"
          render={(record) =>
            t(`resources.reward.expiry.${record.validPeriodUnit}`, {
              smart_count: record.validPeriod,
            })
          }
        />
        <FunctionField
          source="isEnabled"
          label="resources.reward.fields.status"
          render={(record) => <ActiveLabel isActive={record.isEnabled} />}
          {...props}
        />
      </CustomizedList>
      <Route exact path={`/${resourceSlug.REWARD}/:id/edit`}>
        {({ match }) => <RewardCreateUpdateDrawer open={!!match} {...props} />}
      </Route>
      <Route exact path={`/${resourceSlug.REWARD}/create`}>
        {({ match }) => <RewardCreateUpdateDrawer open={!!match} {...props} />}
      </Route>
      <Route exact path={`/${resourceSlug.REWARD}/:id/details`}>
        {({ match }) => <RewardDetailsDrawer open={!!match} {...props} />}
      </Route>
      <Route exact path={`/${resourceSlug.REWARD}/:id/change-log`}>
        {({ match }) => <RewardDetailsDrawer open={!!match} {...props} />}
      </Route>
    </>
  );
};

export default RewardList;
