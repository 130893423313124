/* eslint-disable no-unused-vars, no-underscore-dangle */
import _ from 'lodash';

import { AppBrandEnum, ROLE_TYPE } from '.';

const MBO_LOGO_GREEN_COLUMN = '/images/nagagames-logos/logo-light-transparent.png';
const MBO_LOGO = '/images/nagagames-logos/logo-light-transparent.png';
const MBO_COMPANY_NAME = 'NagaGames';

const NAGA_LOGO = '/images/nagagames-logos/logo-dark-square-transparent.png';
const NAGA_COMPANY_NAME = 'NagaGames';

const _appBrandConfigMapping = {
  [AppBrandEnum.NAGA]: {
    topHeaderLogo: '/images/nagagames-logos/logo-light-transparent.png',
    loadingImage: NAGA_LOGO,
    companyName: NAGA_COMPANY_NAME,
    loginPageLogo: NAGA_LOGO, // Check at route `/login`
    newUserChangePasswordPageLogo: NAGA_LOGO, // Check at route `/require-change-password`
    resetPasswordPageLogo: NAGA_LOGO, // Check at route `/reset-password/:resetToken`
    faviconDefault: 'images/nagagames-logos/logo-dark-white.ico',
    getTabTitle({
      userGroupCode, roleType,
    }) {
      if (roleType === ROLE_TYPE.group) {
        return `Group ${userGroupCode || ''}`;
      }
      // ROLE_TYPE.system
      return `${NAGA_COMPANY_NAME} | Back office`;
    },
  },
  [AppBrandEnum.MBO]: {
    topHeaderLogo: MBO_LOGO,
    loadingImage: MBO_LOGO,
    companyName: MBO_COMPANY_NAME,
    loginPageLogo: MBO_LOGO_GREEN_COLUMN, // Check at route `/login`
    newUserChangePasswordPageLogo: MBO_LOGO_GREEN_COLUMN, // Check at route `/require-change-password`
    resetPasswordPageLogo: MBO_LOGO_GREEN_COLUMN, // Check at route `/reset-password/:resetToken`
    faviconDefault: 'images/nagagames-logos/logo-dark-white.ico',
    getTabTitle({
      userGroupCode, roleType,
    }) {
      if (roleType === ROLE_TYPE.group) {
        return `${MBO_COMPANY_NAME} | Group ${userGroupCode || ''}`;
      }
      // ROLE_TYPE.system
      return `${MBO_COMPANY_NAME} | Back office`;
    },
  },
};

export const appBrandConfigMapping = new Proxy(_appBrandConfigMapping, {
  get(target, prop, _receiver) {
    const targetKeys = _.keys(target);
    if (!targetKeys.includes(prop)) {
      return target[AppBrandEnum.NAGA];
    }
    return target[prop];
  },
});
