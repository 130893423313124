import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDataProvider, useListContext } from 'react-admin';
import InfinitePagination from '../../../../base/components/ra/list/InfinitePagination';
import resourceSlug from '../../../../constant/resource-slug';
import { sanitizeObject } from '../../../../services/util';
import { cacheCheckInsByPage, updateCurrentCheckInPage } from '../../../../services/redux/checkIn/checkIn.action';

const CheckInPagination = () => {
  const { checkInList, currentCheckInPage } = useSelector(({ checkIn }) => checkIn);
  const dispatch = useDispatch();
  const dataProvider = useDataProvider();
  const { filterValues, currentSort, perPage } = useListContext();

  const disableNext = useMemo(
    () => !checkInList?.[currentCheckInPage]?.nextPagination,
    [currentCheckInPage, checkInList],
  );

  const handlePageChange = useCallback(
    async (newPage) => {
      try {
        if (!checkInList[newPage]) {
          const nextPagination = checkInList?.[newPage - 1]?.nextPagination;
          const data = await dataProvider.getList(resourceSlug.CHECK_IN, {
            pagination: {
              perPage,
              cachedPage: newPage,
            },
            sort: currentSort,
            filter: {
              ...filterValues,
              q: sanitizeObject({
                nextPagination: JSON.stringify(nextPagination),
              }),
            },
          });

          dispatch(
            cacheCheckInsByPage({
              data,
              page: newPage,
            }),
          );
        }
        dispatch(updateCurrentCheckInPage(newPage));
      } catch (error) {
        // Do nothing
      }
    },
    [checkInList, currentCheckInPage],
  );

  return (
    <InfinitePagination
      disableNext={disableNext}
      disablePrev={currentCheckInPage === 1}
      onNextPage={() => {
        handlePageChange(currentCheckInPage + 1);
      }}
      onPrevPage={() => {
        handlePageChange(currentCheckInPage - 1);
      }}
      currentPage={currentCheckInPage}
    />
  );
};

export default CheckInPagination;
