import React, { useMemo } from 'react';
import { useTranslate } from 'react-admin';
import CustomizedFilterWrapper from '../../../../base/components/customized/CustomizedFilterWrapper';
import { SelectInput, TextInput } from '../../../../base/components/ra/inputs';
import { CHECK_IN_STATUS, WALLET_TYPES } from '../../../../constant/checkIn';
import DateRangeInput from '../../../../base/components/guesser/date-range-input';
import { SERVER_DATETIME_WITHOUT_TIMEZONE_FORMAT } from '../../../../constant';

const CheckInFilters = () => {
  const t = useTranslate();

  const statusChoices = useMemo(
    () =>
      Object.values(CHECK_IN_STATUS).map((item) => ({
        id: item,
        name: t(`resources.check-in.status.${item}`),
      })),
    [t],
  );

  const walletChoices = useMemo(
    () =>
      Object.values(WALLET_TYPES).map((item) => ({
        id: item,
        name: t(`common.wallet.${item}`),
      })),
    [t],
  );

  return (
    <CustomizedFilterWrapper>
      <DateRangeInput
        source="created"
        label={t('resources.check-in.fields.created')}
        defaultRangeOption="date"
        hasRangeOption={false}
        showTime={false}
        allowClear={[true, true]}
        allowEmpty={[true, true]}
        ranges={[]}
        serverFormat={SERVER_DATETIME_WITHOUT_TIMEZONE_FORMAT}
      />
      <TextInput source="campaignName||$cont" label="resources.check-in.fields.name" resettable />
      <SelectInput
        source="destinationWalletType"
        label="resources.check-in.fields.transfer-destination"
        choices={walletChoices}
        resettable
      />
      <SelectInput source="status" label="resources.check-in.fields.status" choices={statusChoices} resettable />
    </CustomizedFilterWrapper>
  );
};

export default CheckInFilters;
