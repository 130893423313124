import { Box, Drawer, makeStyles, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles({
  paper: {
    padding: 24,
    minWidth: '27rem',
    width: 'fit-content',
    maxWidth: 700,
    display: 'flex',
    flexDirection: 'column',
  },
  root: {
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
});

const MuiDrawer = ({ children, onClose, title, hasCloseButton = true, classes, className, ...props }) => {
  const styles = useStyles();

  return (
    <Drawer
      className={clsx(styles.root, className)}
      classes={{
        ...classes,
        paper: clsx(styles.paper, classes?.paper),
      }}
      anchor="right"
      onClose={onClose}
      {...props}
      ModalProps={{
        disableBackdropClick: true,
        ...props.ModalProps,
      }}
    >
      <Box fontSize="1.1rem" fontWeight={700} mb={2}>
        {title}
      </Box>
      {!!hasCloseButton && (
        <IconButton className={styles.closeButton} onClick={() => onClose()}>
          <Close />
        </IconButton>
      )}
      {children}
    </Drawer>
  );
};

export default MuiDrawer;
