import React, { useEffect } from 'react';
import { required, SimpleForm, useTranslate } from 'react-admin';
import { useForm, useField } from 'react-final-form';
import { Box } from '@material-ui/core';
import pick from 'lodash/pick';
import moment from 'moment';
import CheckInFormToolbar from '../CheckInFormToolbar';
import { TextInput } from '../../../../../base/components/ra/inputs';
import { useCheckInForm, useCheckInValidate } from '../../utils';
import TimezoneInput from './TimezoneInput';
import GroupInput from './GroupInput';
import BrandInput from './BrandInput';
import CurrencyInput from './CurrencyInput';
import DatePickerInput from '../../../../../base/components/ra/inputs/datePicker.input';
import DescriptionInputs from './DescriptionInputs';

const BasicInfoStep = () => {
  const { updateData } = useCheckInForm();

  return (
    <SimpleForm
      toolbar={null}
      save={(values) => {
        const basicInfo = pick(values, [
          'campaignName',
          'currencyId',
          'timezone',
          'startTime',
          'endTime',
          'group.isSelectAll',
          'group.ids',
          'brand.isSelectAll',
          'brand.ids',
          'title',
          'termAndCondition',
        ]);

        updateData(basicInfo);
      }}
    >
      <BasicInfoForm />
      <CheckInFormToolbar />
    </SimpleForm>
  );
};

const BasicInfoForm = () => {
  const t = useTranslate();
  const { initialValues, uneditableFields } = useCheckInForm();
  const { initialize } = useForm();
  const { campaignNameValidator, endDateValidator } = useCheckInValidate();
  const {
    input: { value: startTime },
  } = useField('startTime');

  useEffect(() => {
    if (initialValues) {
      initialize(initialValues);
    }
  }, [initialValues]);

  return (
    <>
      <TextInput
        fullWidth
        source="campaignName"
        label="resources.check-in.fields.name"
        isRequired
        validate={campaignNameValidator}
        disabled={uneditableFields?.includes?.('campaignName')}
      />
      <CurrencyInput />
      <Box
        display="grid"
        gridTemplateColumns="2fr 1fr 1fr"
        alignItems="flex-end"
        sx={{
          gap: 24,
        }}
      >
        <TimezoneInput />
        <DatePickerInput
          validate={required()}
          label={t('resources.check-in.fields.start-date')}
          timeFormat=""
          isRequired
          source="startTime"
          showTime={false}
          disabled={uneditableFields?.includes?.('startTime')}
          disabledDate={(current) => !!current && moment(current).isSameOrBefore(moment())}
        />
        <DatePickerInput
          validate={endDateValidator}
          label={t('resources.check-in.fields.end-date')}
          timeFormat=""
          isRequired
          source="endTime"
          showTime={false}
          disabled={uneditableFields?.includes?.('endTime')}
          disabledDate={(current) => !!current && !!startTime && moment(current).isSameOrBefore(moment(startTime))}
        />
      </Box>
      <GroupInput />
      <BrandInput />
      <DescriptionInputs />
    </>
  );
};

export default BasicInfoStep;
