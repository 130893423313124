import React, { useCallback } from 'react';
import { useTranslate } from 'react-admin';
import { TextInput } from '../../../../base/components/ra/inputs';

const RewardNameInput = (props) => {
  const t = useTranslate();

  const validate = useCallback(
    (value) => {
      const trimmedValue = value?.trim();
      if (!trimmedValue?.length) {
        return t('ra.validation.required');
      }

      if (trimmedValue.length >= 60) {
        return t('ra.validation.maxLength', {
          max: 60,
        });
      }

      return undefined;
    },
    [t],
  );

  return (
    <TextInput
      source="rewardName"
      label="resources.reward.fields.reward-name"
      isRequired
      validate={validate}
      resettable
      {...props}
    />
  );
};

export default RewardNameInput;
