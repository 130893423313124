/* eslint-disable no-case-declarations */
import { CACHE_CONVERSION_HISTORY_BY_PAGE, UPDATE_CURRENT_CONVERSION_HISTORY_PAGE } from '../root.actions';

const initialConversionHistoryStates = {
  conversionHistoryList: {},
  currentConversionHistoryPage: 1,
};

export const conversionHistoryReducer = (state = initialConversionHistoryStates, action) => {
  const newPage = action.payload?.page || 1;

  switch (action.type) {
    case CACHE_CONVERSION_HISTORY_BY_PAGE:
      return {
        ...state,
        currentConversionHistoryPage: newPage,
        conversionHistoryList:
          newPage === 1
            ? {
                1: action.payload?.data,
              }
            : {
                ...state.conversionHistoryList,
                [newPage]: action.payload?.data,
              },
      };
    case UPDATE_CURRENT_CONVERSION_HISTORY_PAGE:
      return {
        ...state,
        currentConversionHistoryPage: action.payload,
      };
    default:
      return state;
  }
};
