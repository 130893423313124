/* eslint-disable import/no-cycle */
import {
  DeleteButton as RaDeleteButton,
  CreateButton as RaCreateButton,
  ListButton as RaListButton,
  EditButton as RaEditButton,
  Button as RaButton,
} from 'react-admin';
import { withStandardButtonProps, withStandardSmallButtonProps } from '../../../hoc/with-standard-button-props';
import RaButtonClone from './Button';
import RaExportButtonCustom from './ExportButton';
import RaSaveButton from './SaveButton';

export const SaveButton = withStandardButtonProps(RaSaveButton);
export const DeleteButton = withStandardButtonProps(RaDeleteButton);
export const CreateButton = withStandardButtonProps(RaCreateButton);
export const ListButton = withStandardButtonProps(RaListButton);
export const EditButton = withStandardButtonProps(RaEditButton);
export const Button = withStandardButtonProps(RaButton);
export const SmallButton = withStandardSmallButtonProps(RaButtonClone);
export const ExportButton = withStandardSmallButtonProps(RaExportButtonCustom);
