import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDataProvider, useListContext } from 'react-admin';
import { useParams } from 'react-router-dom';
import resourceSlug from '../../../../../constant/resource-slug';
import {
  cacheConversionHistoryByPage,
  updateCurrentConversionHistoryPage,
} from '../../../../../services/redux/conversionHistory/conversionHistory.action';
import { sanitizeObject } from '../../../../../services/util';
import InfinitePagination from '../../../../../base/components/ra/list/InfinitePagination';

const ConversionHistoryPagination = () => {
  const { conversionHistoryList, currentConversionHistoryPage } = useSelector(
    ({ conversionHistory }) => conversionHistory,
  );
  const dispatch = useDispatch();
  const dataProvider = useDataProvider();
  const { filterValues, currentSort, perPage } = useListContext();
  const { id } = useParams();

  const disableNext = useMemo(
    () => !conversionHistoryList?.[currentConversionHistoryPage]?.nextPagination,
    [currentConversionHistoryPage, conversionHistoryList],
  );

  const handlePageChange = useCallback(
    async (newPage) => {
      try {
        if (!conversionHistoryList[newPage]) {
          const nextPagination = conversionHistoryList?.[newPage - 1]?.nextPagination;
          const data = await dataProvider.getList(`${resourceSlug.PLAYER}/${id}/wallet-conversion-history`, {
            pagination: {
              perPage,
              cachedPage: newPage,
            },
            sort: currentSort,
            filter: {
              ...filterValues,
              q: sanitizeObject({
                nextPagination: JSON.stringify(nextPagination),
              }),
            },
          });

          dispatch(
            cacheConversionHistoryByPage({
              data,
              page: newPage,
            }),
          );
        }
        dispatch(updateCurrentConversionHistoryPage(newPage));
      } catch (error) {
        // Do nothing
      }
    },
    [conversionHistoryList, currentConversionHistoryPage],
  );

  return (
    <InfinitePagination
      disableNext={disableNext}
      disablePrev={currentConversionHistoryPage === 1}
      onNextPage={() => {
        handlePageChange(currentConversionHistoryPage + 1);
      }}
      onPrevPage={() => {
        handlePageChange(currentConversionHistoryPage - 1);
      }}
      currentPage={currentConversionHistoryPage}
    />
  );
};

export default ConversionHistoryPagination;
