/* eslint-disable no-param-reassign, no-shadow, react/prop-types, no-unused-vars */
import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import { ListContextProvider, useRecordContext } from 'ra-core';
import { keyBy } from 'lodash';
import { Paper, makeStyles } from '@material-ui/core';
import Pagination from '../../customizable-colums/Pagination';
import Datagrid from '../data-grid';

const initialState = {
  data: {},
  ids: [],
};

const useClasses = makeStyles((theme) => ({
  paper: {
    border: `1px solid ${theme.palette.grey[300]}`,
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 8,
    padding: '0px 16px',
    '& table': {
      border: 'none',
      flexGrow: 1,
      '& th, & td': {
        borderRight: 'none',
        borderBottomColor: theme.palette.grey[300],
      },
      '& td': {
        padding: '10px 16px',
      },
      '& th': {
        padding: 16,
      },
      '& th:not(:last-child)': {
        '&:before': {
          position: 'absolute',
          top: '50%',
          right: 0,
          width: 1,
          height: '1.6em',
          backgroundColor: theme.palette.grey[300],
          transform: 'translateY(-50%)',
          content: '""',
        },
      },
    },
    '& *[class*="MuiTablePagination-root"]': {
      fontWeight: 500,
      marginTop: 'auto',
    },
  },
}));

const getDataAndIds = (record, source, fieldKey) => {
  const list = get(record, source);
  if (!list) {
    return initialState;
  }
  return fieldKey
    ? {
        data: list.reduce((prev, item) => {
          prev[item[fieldKey]] = item;
          return prev;
        }, {}),
        ids: list.map((item) => item[fieldKey]),
      }
    : {
        data: list.reduce((prev, item) => {
          prev[JSON.stringify(item)] = item;
          return prev;
        }, {}),
        ids: list.map(JSON.stringify),
      };
};

const ArrayFieldImpl = (props) => {
  const {
    addLabel,
    basePath,
    children,
    record: _record,
    resource,
    sortable,
    source,
    fieldKey = 'id',
    loading = false,
    loaded = true,
    rowClick,
    ...rest
  } = props;
  const classes = useClasses();
  const record = useRecordContext(props);
  const [ids, setIds] = useState(initialState.ids);
  const [data, setData] = useState(initialState.data);
  const [currentIds, setCurrentIds] = useState(initialState.ids);
  const [currentData, setCurrentData] = useState(initialState.data);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);

  const onPageChange = (newPage) => {
    setPage(newPage);
    const startIndex = (newPage - 1) * perPage + newPage;
    setCurrentIds(ids.slice(startIndex, startIndex + perPage));
    setCurrentData(keyBy(Object.values(data).slice(startIndex, startIndex + perPage), 'id'));
  };

  const onPerPageChange = (newPerPage) => {
    setPage(1);
    setPerPage(newPerPage);
    setCurrentIds(ids.slice(0, newPerPage));
    setCurrentData(keyBy(Object.values(data).slice(0, newPerPage), 'id'));
  };

  useEffect(() => {
    const { ids, data } = getDataAndIds(record, source, fieldKey);

    setIds(ids);
    setData(data);
    setCurrentIds(ids.slice(0, perPage));
    setCurrentData(keyBy(Object.values(data).slice(0, perPage), 'id'));
  }, [record, source, fieldKey]);

  return (
    <ListContextProvider
      value={{
        ids: currentIds,
        data: currentData,
        loading,
        basePath,
        selectedIds: [],
        currentSort: {
          field: null,
          order: null,
        },
        displayedFilters: null,
        filterValues: null,
        hasCreate: null,
        hideFilter: null,
        loaded,
        onSelect: null,
        onToggleItem: null,
        onUnselectItems: null,
        page,
        perPage,
        resource,
        setFilters: null,
        setPage: onPageChange,
        setPerPage: onPerPageChange,
        setSort: null,
        showFilter: null,
        total: ids.length,
      }}
    >
      <Paper className={classes.paper}>
        <Datagrid rowClick={rowClick}>{children}</Datagrid>
        <Pagination />
      </Paper>
    </ListContextProvider>
  );
};

ArrayFieldImpl.displayName = 'ArrayFieldImpl';

const ArrayWithPaginationField = React.memo(ArrayFieldImpl);

export default ArrayWithPaginationField;
