import React, { useMemo } from 'react';
import { useTranslate } from 'react-admin';
import { useParams } from 'react-router-dom';
import { useField, useForm } from 'react-final-form';
import CustomizedFilterWrapper from '../../../../../base/components/customized/CustomizedFilterWrapper';
import DateRangePickerInput from '../../../../../base/components/guesser/date-range-input';
import resourceSlug from '../../../../../constant/resource-slug';
import { SelectInput, TextInput } from '../../../../../base/components/ra/inputs';
import { REWARD_TYPES } from '../../../../../constant/reward';
import { PLAYER_REWARD_STATUS } from '../../../../../constant/checkIn';
import BrandSelector from '../../../../../base/components/customized/common-input/BrandSelector';
import GroupSelector from '../../../../../base/components/customized/common-input/GroupSelector';
import { useAuthUser, useEffectAfterMount } from '../../../../../base/hooks';

const CheckInPlayerRewardFilters = () => {
  const t = useTranslate();
  const { id } = useParams();

  const statusChoices = useMemo(
    () =>
      Object.values(PLAYER_REWARD_STATUS).map((status) => ({
        id: status,
        name: t(`resources.check-in.player-reward-status.${status}`),
      })),
    [t],
  );

  const rewardTypeChoices = useMemo(
    () =>
      Object.values(REWARD_TYPES).map((type) => ({
        id: type,
        name: t(`resources.reward.reward-type.${type}`),
      })),
    [t],
  );

  return (
    <CustomizedFilterWrapper>
      <GroupFilter />
      <BrandFilter />
      <TextInput source="nativeId||$eq" label="common.fields.nativeId" resettable />
      <DateRangeInput
        source="issuedDate"
        label={t('resources.campaign.fields.issued-date')}
        resource={`${resourceSlug.CHECK_IN}/${id}/player-rewards`}
      />
      <DateRangeInput
        source="usedDate"
        label={t('resources.campaign.fields.claimed-date')}
        resource={`${resourceSlug.CHECK_IN}/${id}/player-rewards`}
      />
      <SelectInput
        source="rewardType"
        label="resources.reward.fields.reward-type"
        choices={rewardTypeChoices}
        resettable
      />
      <SelectInput source="status" label="common.fields.status" choices={statusChoices} resettable />
    </CustomizedFilterWrapper>
  );
};

const BRAND_KEY = 'brandId';
const GROUP_KEY = 'groupId';

const GroupFilter = () => {
  const user = useAuthUser();
  const userGroupId = user.group?.id;

  return <GroupSelector source={GROUP_KEY} defaultValue={userGroupId} disabled={Boolean(userGroupId)} />;
};

const BrandFilter = () => {
  const user = useAuthUser();
  const form = useForm();
  const groupId = useField(GROUP_KEY)?.input?.value;
  const brandId = useField(BRAND_KEY)?.input?.value;
  const userBrandId = user?.brands?.[0]?.id;

  useEffectAfterMount(() => {
    if (!brandId || !groupId) return;
    form.change(BRAND_KEY, undefined);
  }, [groupId]);

  return (
    <BrandSelector
      source={BRAND_KEY}
      defaultValue={userBrandId}
      disabled={Boolean(userBrandId)}
      filter={{
        'group.id': groupId,
      }}
    />
  );
};

const DateRangeInput = (props) => <DateRangePickerInput prefix ranges={[]} {...props} />;

export default CheckInPlayerRewardFilters;
