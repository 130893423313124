import { Box, Chip, useTheme } from '@material-ui/core';
import React from 'react';
import { useTranslate } from 'react-admin';

const ActiveLabel = ({
  isActive, activeLabel, inactiveLabel,
}) => {
  const t = useTranslate();
  const theme = useTheme();

  return (
    <Box
      component={Chip}
      sx={{
        "&[class*='Chip']": {
          borderRadius: 6,
          padding: '3px 12px',
          backgroundColor: isActive ? theme.palette.success.main : theme.palette.error.light,
          color: 'white',
          '& span': {
            padding: '0 !important', // To override default styles
          },
        },
      }}
      label={(isActive ? activeLabel : inactiveLabel) ?? t(`resources.reward.status.${+!!isActive}`)}
    />
  );
};

export default ActiveLabel;
