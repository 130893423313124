import React, { useMemo } from 'react';
import { required, useTranslate } from 'react-admin';
import { useField } from 'react-final-form';
import { Box } from '@material-ui/core';
import Switcher from '../../../../../../base/components/common/Switcher';
import { SelectInput } from '../../../../../../base/components/ra/inputs';
import { getTimezoneByTzId, useCheckInForm, useCheckInValidate } from '../../../utils';
import InputWithTooltipWrapper from '../../../../../../base/components/common/InputWithTooltipWrapper';

const TimeFrameInput = () => {
  const t = useTranslate();
  const { data, uneditableFields } = useCheckInForm();
  const { timezone } = data.current;
  const {
    input: { value: timeFrameRequired },
  } = useField('timeFrameRequired');

  const { toCheckInValidator } = useCheckInValidate();

  const fromChoices = useMemo(
    () =>
      Array.from(
        {
          length: 24,
        },
        (_, value) => ({
          id: `${`${value}`.padStart(2, '0')}:00:00`,
          name: `${`${value}`.padStart(2, '0')}:00:00 (GMT${getTimezoneByTzId(timezone)?.GMT})`,
        }),
      ),
    [timezone],
  );

  const toChoices = useMemo(
    () =>
      Array.from(
        {
          length: 24,
        },
        (_, value) => ({
          id: `${`${value}`.padStart(2, '0')}:59:59`,
          name: `${`${value}`.padStart(2, '0')}:59:59 (GMT${getTimezoneByTzId(timezone)?.GMT})`,
        }),
      ),
    [timezone],
  );

  return (
    <Box
      display="flex"
      sx={{
        gap: 18,
      }}
    >
      <InputWithTooltipWrapper
        source="timeFrameRequired"
        title={t('resources.check-in.fields.time-frame')}
        tooltip={t('resources.check-in.fields.time-frame-tooltip')}
        disabled={!!uneditableFields?.includes?.('fromCheckInTime') && !!uneditableFields?.includes?.('toCheckInTime')}
      >
        <Box
          component={Switcher}
          defaultValue={false}
          mt={2}
          sx={{
            '& label': {
              flexDirection: 'row-reverse',
              gap: 18,
            },
          }}
        />
      </InputWithTooltipWrapper>

      {timeFrameRequired && (
        <Box
          flexGrow={1}
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          sx={{
            gap: 24,
          }}
        >
          <SelectInput
            source="fromCheckInTime"
            label="resources.check-in.fields.from-check-in-time"
            isRequired
            validate={[required()]}
            choices={fromChoices}
            disabled={!!uneditableFields?.includes?.('fromCheckInTime')}
          />
          <SelectInput
            source="toCheckInTime"
            label="resources.check-in.fields.to-check-in-time"
            isRequired
            validate={toCheckInValidator}
            choices={toChoices}
            disabled={!!uneditableFields?.includes?.('toCheckInTime')}
          />
        </Box>
      )}
    </Box>
  );
};

export default TimeFrameInput;
