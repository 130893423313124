import React from 'react';
import { required, useTranslate, minValue } from 'react-admin';
import { Box } from '@material-ui/core';
import SelectInput from '../../../../base/components/ra/inputs/SelectInput';
import { REWARD_EXPIRY_UNITS } from '../../../../constant/reward';
import { IntegerInput } from '../../../../base/components/ra/inputs';

const ExpiryInput = () => {
  const t = useTranslate();

  return (
    <Box
      width="100% !important"
      display="grid"
      sx={{
        gap: 8,
        gridTemplateColumns: 'auto 130px',
      }}
    >
      <IntegerInput
        source="validPeriod"
        label={t('resources.reward.fields.expiry')}
        fullWidth
        isRequired
        validate={[
          required(),
          minValue(
            1,
            t('ra.validation.greater', {
              min: 0,
            }),
          ),
        ]}
        resettable
      />
      <Box
        component={SelectInput}
        choices={Object.values(REWARD_EXPIRY_UNITS).map((unit) => ({
          id: unit,
          name: t(`resources.reward.expiry-unit.${unit}`),
        }))}
        source="validPeriodUnit"
        label={false}
        isRequired
        validate={required()}
        fullWidth
        sx={{
          minWidth: '130px !important',
        }}
        defaultValue={REWARD_EXPIRY_UNITS.DAYS}
        placeholder={t('resources.reward.fields.expiry-unit')}
      />
    </Box>
  );
};

export default ExpiryInput;
