import { announcementReducer } from './announcement/announcement.reducer';
import { appReducer } from './app/app.reducer';
import { authReducer } from './auth/auth.reducer';
import { filterReducer } from './filter/filter.reducer';
import { schemaReducer } from './schema/schema.reducer';
import { settingReducer } from './setting/setting.reducer';
import { betReducer } from './bet/bet.reducer';
import { wagerReducer } from './wager/wager.reducer';
import { largeWinReducer } from './largeWin/largeWin.reducer';
import { playerCampaignUsageReducer } from './playerCampaignUsage/playerCampaignUsage.reducer';
import { campaignReducer } from './campaign/campaign.reducer';
import { rewardReducer } from './reward/reward.reducer';
import { checkInReducer } from './checkIn/checkIn.reducer';
import { conversionHistoryReducer } from './conversionHistory/conversionHistory.reducer';

const rootReducer = {
  setting: settingReducer,
  schema: schemaReducer,
  auth: authReducer,
  announcement: announcementReducer,
  filter: filterReducer,
  app: appReducer,
  bet: betReducer,
  wager: wagerReducer,
  largeWin: largeWinReducer,
  playerCampaignUsage: playerCampaignUsageReducer,
  campaign: campaignReducer,
  reward: rewardReducer,
  checkIn: checkInReducer,
  conversionHistory: conversionHistoryReducer,
};

export default rootReducer;
