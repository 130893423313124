import CheckInCampaignCreate from './check-in.create';
import CheckInCampaignList from './check-in.list';
import CheckInCampaignUpdate from './check-in.edit';
import CheckInCampaignShow from './check-in.show';

export default {
  create: CheckInCampaignCreate,
  edit: CheckInCampaignUpdate,
  list: CheckInCampaignList,
  show: CheckInCampaignShow,
};
