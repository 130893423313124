import React, { useMemo } from 'react';
import { useTranslate } from 'react-admin';
import CustomizedFilterWrapper from '../../../../base/components/customized/CustomizedFilterWrapper';
import { SelectInput, TextInput } from '../../../../base/components/ra/inputs';
import { REWARD_TYPES } from '../../../../constant/reward';

const RewardFilterWrapper = () => {
  const t = useTranslate();

  const statusChoices = useMemo(
    () =>
      [true, false].map((value) => ({
        id: value,
        name: t(`resources.reward.status.${+value}`),
      })),
    [t],
  );

  const rewardTypeChoices = useMemo(
    () =>
      Object.values(REWARD_TYPES).map((type) => ({
        id: type,
        name: t(`resources.reward.reward-type.${type}`),
      })),
    [t],
  );

  return (
    <CustomizedFilterWrapper>
      <TextInput source="rewardName||$cont" label="resources.reward.fields.reward-name" resettable />
      <SelectInput
        source="rewardType"
        label="resources.reward.fields.reward-type"
        choices={rewardTypeChoices}
        resettable
      />
      <SelectInput source="isEnabled" label="resources.reward.fields.status" choices={statusChoices} resettable />
    </CustomizedFilterWrapper>
  );
};

export default RewardFilterWrapper;
