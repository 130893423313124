import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { Show } from 'react-admin';

const DrawerShow = (props) => {
  const classes = useStyles();

  return (
    <Show
      {...props}
      classes={{
        ...props.classes,
        root: clsx(classes.root, props.classes?.root),
        card: clsx(classes.card, props.classes?.card),
      }}
    />
  );
};

const useStyles = makeStyles({
  card: {
    boxShadow: 'none',
  },
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    '&>*:first-child': {
      height: 'calc(100% - 1em)',
    },
  },
});

export default DrawerShow;
