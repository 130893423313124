/* eslint-disable no-param-reassign, prefer-destructuring, no-useless-escape, no-shadow, import/no-cycle, no-nested-ternary */
import { CondOperator, RequestQueryBuilder } from '@nestjsx/crud-request';
import axios from 'axios';
import { fetchUtils, HttpError } from 'react-admin';
import { camelCase, flatten, startCase, upperCase, isObject, set, get } from 'lodash';
import { stringify } from 'querystring';
import flattenDeep from 'lodash/flattenDeep';
import { convertFileToBase64 } from '../util/convertFileToBase64';
import { handleCompressImage } from '../util/imageUploadCompress';
import { handleDetailsData } from '../util';
import { stripResourceBuiltinData } from '../util/strip-resource-builtin-data';
import { FILTER_PREFIX_ON_VALUE } from '../../constant';
import { handleNotifyErrorOutside } from '../axios/interceptor.controller';
import resourceSlug from '../../constant/resource-slug';
import { getHook } from '../util/react-hooks-outside';
import { cacheBetsByPage } from '../redux/bet/bet.action';
import { cacheWagerByPage } from '../redux/wager/wager.action';
import { cacheLargeWinByPage } from '../redux/largeWin/largeWin.action';
import { cachePlayerCampaignUsageByPage } from '../redux/playerCampaignUsage/playerCampaignUsage.action';
import {
  cacheCampaignActionLogByPage,
  cacheCampaignPlayerBatchByPage,
  cacheCampaignPlayersByPage,
  cacheCampaignsByPage,
} from '../redux/campaign/campaign.action';
import { cacheRewardsByPage } from '../redux/reward/reward.action';
import { cacheCheckInPlayerRewardsByPage, cacheCheckInsByPage } from '../redux/checkIn/checkIn.action';
import { cacheConversionHistoryByPage } from '../redux/conversionHistory/conversionHistory.action';
import { i18nProvider } from './i18n/i18nProvider';

const httpClient = async (args) =>
  new Promise((resolve, reject) => {
    axios
      .request({
        ...args,
        method: args.method || 'get',
        // eslint-disable-next-line no-unused-vars
        validateStatus: (code) => true,
      })
      .then((resData) => {
        const { data } = resData;

        if (data.statusCode || data.errorCode) {
          const errorMessage = i18nProvider.translate(data.errorCode)
            ? `error.${data.errorCode}`
            : (Array.isArray(data.message) ? data.message.join(', ') : data.message, data.statusCode, data);
          return reject(new HttpError(errorMessage));
        }

        if (resData.status === 413) {
          throw new HttpError('Request Entity Too Large');
        }

        if (resData.status >= 400) {
          return reject(
            new HttpError(
              `${(Array.isArray(data.message) ? data.message.join(', ') : data.message) || resData.statusText}`,
              resData.status,
              data,
            ),
          );
        }

        return resolve({
          ...resData,
          data,
        });
      })
      .catch((error) => {
        handleNotifyErrorOutside(error);
      });
  });

export const composeFilter = (paramsFilter) => {
  const flatFilter = fetchUtils.flattenObject(
    Object.keys(paramsFilter)
      .filter((key) => {
        const [, ops] = key.split('||');
        return key !== '$or' && ops !== '$ignores';
      })
      .reduce(
        (obj, key) => ({
          ...obj,
          [key]: paramsFilter[key],
        }),
        {},
      ),
  );

  return Object.keys(flatFilter)
    .filter((key) => typeof flatFilter[key] !== 'object' || (Array.isArray(flatFilter[key]) && flatFilter[key].length)) // skip the empty-object filterValue when add new filter
    .map((key) => {
      let filterValue = flatFilter[key];
      let [field, ops] = key.split('||');

      if (!ops) {
        switch (true) {
          case ['boolean'].includes(typeof filterValue):
            ops = CondOperator.EQUALS;
            break;
          case filterValue.startsWith(FILTER_PREFIX_ON_VALUE.EQUALS):
            ops = CondOperator.EQUALS;
            filterValue = filterValue.split(FILTER_PREFIX_ON_VALUE.EQUALS)[1];
            break;
          case filterValue.startsWith(FILTER_PREFIX_ON_VALUE.BETWEEN):
            ops = CondOperator.BETWEEN;
            filterValue = filterValue.split(FILTER_PREFIX_ON_VALUE.BETWEEN)[1];
            break;
          case filterValue.startsWith(FILTER_PREFIX_ON_VALUE.GREATER_THAN_EQUALS):
            ops = CondOperator.GREATER_THAN_EQUALS;
            filterValue = filterValue.split(FILTER_PREFIX_ON_VALUE.GREATER_THAN_EQUALS)[1];
            break;
          case filterValue.startsWith(FILTER_PREFIX_ON_VALUE.LOWER_THAN_EQUALS):
            ops = CondOperator.LOWER_THAN_EQUALS;
            filterValue = filterValue.split(FILTER_PREFIX_ON_VALUE.LOWER_THAN_EQUALS)[1];
            break;
          case filterValue.startsWith(FILTER_PREFIX_ON_VALUE.CONTAINS):
            ops = CondOperator.CONTAINS;
            filterValue = filterValue.split(FILTER_PREFIX_ON_VALUE.CONTAINS)[1];
            break;
          case field.match(/(-[>]{1,2})\'\$[a-zA-Z0-9._\[\]]{1,}\'/)?.length > 0:
            ops = CondOperator.CONTAINS;
            break;
          default:
            // ops = CondOperator.CONTAINS;
            ops = CondOperator.EQUALS;
        }
      }

      if (field.startsWith('_') && field.includes('.')) {
        [, field] = field.split(/\.(.+)/);
      }

      return {
        field,
        operator: ops,
        value: filterValue,
      };
    });
};

export const composeOr = (paramsFilter) => {
  const orParams = flatten(
    Object.keys(paramsFilter)
      .filter((key) => key === '$or')
      .map((key) => paramsFilter[key]),
  );
  return orParams.map((item) => {
    const splitKey = item?.split('||');
    const field = splitKey[0];
    const operator = splitKey[1];
    const value = splitKey?.[2];
    return {
      field,
      operator,
      value,
    };
  });
};

export const composeQueryParams = (queryParams = {}) => stringify(fetchUtils.flattenObject(queryParams));
export const mergeEncodedQueries = (...encodedQueries) => encodedQueries.map((query) => query).join('&');

export const validOrderSort = (sort) => {
  if (!isObject(sort)) {
    return sort;
  }

  if (!['ASC', 'DESC'].includes(upperCase(sort.order))) {
    return {
      ...sort,
      order: 'ASC',
    };
  }

  return {
    ...sort,
    order: upperCase(sort.order),
  };
};

export const getQueryUrl = (resource, params) => {
  const { page = 1, perPage = 25 } = params?.pagination || {};
  const { q: queryParams, ...filter } = params?.filter || {};

  // Get rid of null/undefined/empty string search value
  const enhancedFilters = Object.entries(filter || {}).reduce((obj, [key, value]) => {
    if (!Number.isInteger(value) && typeof value !== 'boolean') {
      if (!value) {
        return obj;
      }
    }
    obj[key] = value;
    return obj;
  }, {});

  const encodedQueryParams = composeQueryParams(queryParams);
  const encodedQueryFilter = RequestQueryBuilder.create({
    filter: composeFilter(enhancedFilters, resource),
    or: composeOr(enhancedFilters),
  })
    .setLimit(perPage)
    .setPage(page)
    .sortBy(validOrderSort(params.sort))
    .setOffset((page - 1) * perPage)
    .query();
  const query = mergeEncodedQueries(encodedQueryParams, encodedQueryFilter);
  const url = `api/${resource}?${query}`;

  return {
    query,
    url,
  };
};

const crudProvider = (apiUrl) => ({
  getAll: (resource, params) => {
    const { q: queryParams, ...filter } = params.filter || {};

    const encodedQueryParams = composeQueryParams(queryParams);
    const queryBuilder = RequestQueryBuilder.create({
      filter: composeFilter(filter, resource),
    });

    if (params.sort) {
      queryBuilder.sortBy(validOrderSort(params.sort));
    }

    const encodedQueryFilter = queryBuilder.query();
    const query = mergeEncodedQueries(encodedQueryParams, encodedQueryFilter);
    const url = `${apiUrl}/${resource}?${query}`.replace(/\?\&$/g, '');

    return httpClient({
      url,
    }).then(({ data }) => ({
      data: data || [],
      total: data?.length,
    }));
  },

  getList: (resource, params) => {
    let data;

    const campaignPlayersRegex = /^mkt-free-spin\/\d*\/players$/;
    const campaignActionLogRegex = /^mkt-free-spin\/\d*\/action-log$/;
    const campaignPlayerBatchRegex = /^mkt-free-spin\/\d*\/player-batch$/;

    const checkInPlayerRewardsRegex = /^check-in\/\d*\/player-rewards$/;

    const conversionHistoryRegex = /^player\/\d*\/wallet-conversion-history$/;

    try {
      if (
        [
          resourceSlug.BET,
          resourceSlug.WAGER,
          resourceSlug.LARGE_WIN,
          resourceSlug.MKT_FREE_SPINS,
          resourceSlug.REPORT_PLAYER_MKT_CAMPAIGN,
          resourceSlug.REWARD,
          resourceSlug.CHECK_IN,
        ].includes(resource) ||
        campaignPlayersRegex.test(resource) ||
        campaignActionLogRegex.test(resource) ||
        campaignPlayerBatchRegex.test(resource) ||
        checkInPlayerRewardsRegex.test(resource) ||
        conversionHistoryRegex.test(resource)
      ) {
        /* Get bet/wager/largeWin/campaign/campaign-action-log/campaign-players/check-in usage list */
        const { perPage = 25, cachedPage = 1 } = params.pagination;
        const { q: queryParams, ...filter } = params.filter || {};

        const dispatch = getHook('useDispatch');

        // Get rid of null/undefined/empty string search value
        const enhancedFilters = Object.entries(filter || {}).reduce((obj, [key, value]) => {
          if (!Number.isInteger(value) && typeof value !== 'boolean') {
            if (!value) {
              return obj;
            }
          }
          obj[key] = value;
          return obj;
        }, {});

        const encodedQueryParams = composeQueryParams(queryParams);
        const encodedQueryFilter = RequestQueryBuilder.create({
          filter: composeFilter(enhancedFilters, resource),
          or: composeOr(enhancedFilters),
        })
          .setLimit(perPage)
          .sortBy(validOrderSort(params.sort))
          .query();
        const query = mergeEncodedQueries(encodedQueryParams, encodedQueryFilter);
        const url = `${apiUrl}/${resource}?${query}`;
        data = httpClient({
          url,
        }).then(({ data: { data, nextPagination } }) => {
          let cacheByPage;
          switch (true) {
            case resource === resourceSlug.CHECK_IN:
              cacheByPage = cacheCheckInsByPage;
              break;
            case resource === resourceSlug.REWARD:
              cacheByPage = cacheRewardsByPage;
              break;
            case resource === resourceSlug.BET:
              cacheByPage = cacheBetsByPage;
              break;
            case resource === resourceSlug.WAGER:
              cacheByPage = cacheWagerByPage;
              break;
            case resource === resourceSlug.LARGE_WIN:
              cacheByPage = cacheLargeWinByPage;
              break;
            case resource === resourceSlug.MKT_FREE_SPINS:
              cacheByPage = cacheCampaignsByPage;
              break;
            case resource === resourceSlug.REPORT_PLAYER_MKT_CAMPAIGN:
              cacheByPage = cachePlayerCampaignUsageByPage;
              break;
            case checkInPlayerRewardsRegex.test(resource):
              cacheByPage = cacheCheckInPlayerRewardsByPage;
              break;
            case campaignPlayersRegex.test(resource):
              cacheByPage = cacheCampaignPlayersByPage;
              break;
            case campaignActionLogRegex.test(resource):
              cacheByPage = cacheCampaignActionLogByPage;
              break;
            case campaignPlayerBatchRegex.test(resource):
              cacheByPage = cacheCampaignPlayerBatchByPage;
              break;
            case conversionHistoryRegex.test(resource):
              cacheByPage = cacheConversionHistoryByPage;
              break;
            default:
              break;
          }

          dispatch(
            cacheByPage({
              data: {
                data,
                nextPagination,
              },
              page: cachedPage,
            }),
          );
          return {
            data,
            /* In those cases, total value isn't returned */
            total: null,
            nextPagination,
          };
        });
      } else {
        /* Get other lists */
        const { page, perPage = 25 } = params.pagination;
        const { q: queryParams, ...filter } = params.filter || {};

        // Get rid of null/undefined/empty string search value
        const enhancedFilters = Object.entries(filter || {}).reduce((obj, [key, value]) => {
          if (!Number.isInteger(value) && typeof value !== 'boolean') {
            if (!value) {
              return obj;
            }
          }
          obj[key] = value;
          return obj;
        }, {});

        const encodedQueryParams = composeQueryParams(queryParams);
        const encodedQueryFilter = RequestQueryBuilder.create({
          filter: composeFilter(enhancedFilters, resource),
          or: composeOr(enhancedFilters),
        })
          .setLimit(perPage)
          .setPage(page)
          .sortBy(validOrderSort(params.sort))
          .setOffset((page - 1) * perPage)
          .query();
        const query = mergeEncodedQueries(encodedQueryParams, encodedQueryFilter);
        const url = `${apiUrl}/${resource}?${query}`;
        data = httpClient({
          url,
        }).then(({ data: { data, total, summary, totalCount } }) => ({
          data,
          /** API can return one of following formats:
           * 1. { ...total: number }
           * 2. { totalCount: number }
           * */
          total: total ?? totalCount ?? null,
          summary,
        }));
      }

      return data;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  },
  getOne: (resource, params) =>
    httpClient({
      url: `${apiUrl}/${resource}/${params.id}`,
    }).then(({ data }) => {
      /** API can return one of following formats:
       * 1. { data: object }
       * 2. { data: { data: object } }
       * */
      const fetchedData = data?.data || data;
      handleDetailsData(fetchedData, resource);
      return {
        data: fetchedData,
      };
    }),
  getOneWithFilter: (resource, params) => {
    const perPage = 25;
    const page = 1;

    const { q: queryParams, ...filter } = params.filter || {};

    // Get rid of null/undefined/empty string search value
    const enhancedFilters = Object.entries(filter || {}).reduce((obj, [key, value]) => {
      if (!Number.isInteger(value) && typeof value !== 'boolean') {
        if (!value) {
          return obj;
        }
      }
      obj[key] = value;
      return obj;
    }, {});

    const encodedQueryParams = composeQueryParams(queryParams);
    const encodedQueryFilter = RequestQueryBuilder.create({
      filter: composeFilter(enhancedFilters, resource),
      or: composeOr(enhancedFilters),
    })
      .setLimit(perPage)
      .setPage(page)
      .sortBy(validOrderSort(params.sort))
      .setOffset((page - 1) * perPage)
      .query();
    const query = mergeEncodedQueries(encodedQueryParams, encodedQueryFilter);
    const url = `${apiUrl}/${resource}?${query}`;

    return httpClient({
      url,
    }).then(({ data: { data, total, summary } }) => ({
      data: data?.[0] || null,
      total,
      summary,
    }));
  },

  getMany: (resource, params) => {
    const idsQuery = get(params, 'ids', []);
    let query = '';

    /**
     * Hotfix: In invoicing report, it's necessary to add skipACL = true when fetching currency fields in result table
     * to get currencies without the care of access permission by group/brand
     * */
    if (window?.location?.hash?.includes(resourceSlug.REPORT_INVOICING) && resource === resourceSlug.CURRENCY) {
      query = RequestQueryBuilder.create()
        .setFilter([
          {
            field: 'id',
            operator: idsQuery.length > 1 ? CondOperator.IN : CondOperator.EQUALS,
            value: `${params.ids}`,
          },
          {
            field: 'skipACL',
            operator: CondOperator.EQUALS,
            value: true,
          },
        ])
        .query();
    } else {
      query = RequestQueryBuilder.create()
        .setFilter({
          field: 'id',
          operator: idsQuery.length > 1 ? CondOperator.IN : CondOperator.EQUALS,
          value: `${params.ids}`,
        })
        .setLimit(idsQuery.length)
        .setPage(1)
        .query();
    }

    const url = `${apiUrl}/${resource}?${query}`;
    return httpClient({
      url,
    }).then(({ data }) => ({
      /** API can return one of following formats:
       * 1. { data: any[] }
       * 2. { data: { data: any[], limit: number, offset: number, totalCount: number, totalPage: number } }
       * */
      data: data?.data || data,
    }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { q: queryParams, ...otherFilters } = params.filter || {};
    const filter = composeFilter(otherFilters, resource);
    filter.push({
      field: params.target,
      operator: CondOperator.EQUALS,
      value: params.id,
    });

    const encodedQueryParams = composeQueryParams(queryParams);
    const encodedQueryFilter = RequestQueryBuilder.create({
      filter,
    })
      .sortBy(validOrderSort(params.sort))
      .setLimit(perPage)
      .setOffset((page - 1) * perPage)
      .query();

    const query = mergeEncodedQueries(encodedQueryParams, encodedQueryFilter);
    const url = `${apiUrl}/${resource}?${query}`;

    return httpClient({
      url,
    }).then(({ data: { data, total } }) => ({
      data,
      total,
    }));
  },

  update: (resource, params) =>
    httpClient({
      url: `${apiUrl}/${resource}/${params.id}`,
      method: 'patch',
      data: stripResourceBuiltinData(params?.data),
    }).then(({ data }) => ({
      /** API can return one of following formats:
       * 1. { data: object }
       * 2. { data: { data: object } }
       * */
      data: data?.data || data,
    })),

  submit: (resource, params) =>
    httpClient({
      url: `${apiUrl}/${resource}/${params.id}/submit`,
      method: 'post',
    }).then(({ data }) => ({
      data,
    })),

  updateMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient({
          url: `${apiUrl}/${resource}/${id}`,
          method: 'patch',
          data: params.data,
        }).then(({ data }) => data),
      ),
    ).then((data) => ({
      data,
    })),

  create: (resource, params) =>
    httpClient({
      url: `${apiUrl}/${resource}`,
      method: 'post',
      data: params.data,
    }).then(({ data }) => ({
      data: {
        ...params.data,
        /** API can return one of following formats:
         * 1. { data: object }
         * 2. { data: { data: object } }
         * */
        id: data?.data?.id || data.id,
      },
    })),

  delete: (resource, params) =>
    httpClient({
      url: `${apiUrl}/${resource}/${params.id}`,
      method: 'delete',
    }).then(({ data }) => ({
      data: {
        ...data,
        id: params.id,
      },
    })),

  deleteMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient({
          url: `${apiUrl}/${resource}/${id}`,
          method: 'delete',
        }),
      ),
    ).then(() => ({
      data: params.ids,
    })),

  put: async (resource, params) =>
    httpClient({
      url: `${apiUrl}/${resource}${params.id ? `/${params.id}` : ''}`,
      method: 'put',
      data: params.data,
    }).then(({ data }) => ({
      data,
    })),

  getCustomList: (resource, params = {}) => {
    try {
      const { page = 1, perPage = 25 } = params.pagination || {};
      const { q: queryParams, ...filter } = params.filter || {};

      // Get rid of null/undefined/empty string search value
      const enhancedFilters = Object.entries(filter || {}).reduce((obj, [key, value]) => {
        if (!Number.isInteger(value) && typeof value !== 'boolean') {
          if (!value) {
            return obj;
          }
        }
        obj[key] = value;
        return obj;
      }, {});

      const offset = perPage && typeof page === 'number' ? (page - 1) * perPage : null;

      const encodedQueryParams = composeQueryParams(queryParams);
      const encodedQueryFilter = RequestQueryBuilder.create({
        filter: composeFilter(enhancedFilters, resource),
        or: composeOr(enhancedFilters),
      })
        .setLimit(perPage)
        .setPage(page)
        .sortBy(validOrderSort(params.sort))
        .setOffset(offset)
        .query();

      const query = mergeEncodedQueries(encodedQueryParams, encodedQueryFilter);
      const url = `${apiUrl}/${resource}?${query}`;
      return httpClient({
        url,
      }).then((data) => data);
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  },
});

const pascalCase = (str) => startCase(camelCase(str)).replace(/ /g, '');

const parseBase64Data = async (resource, params) => {
  // Check has image field
  let realResource;
  realResource = pascalCase(resource.split('/')[0]);

  if (resource.includes('/')) {
    realResource = pascalCase(resource.split('/')[1]);
  }

  const { data } = await axios.get('api/json');
  const dataProperties =
    data.components.schemas[realResource]?.properties || data.components.schemas[upperCase(realResource)]?.properties;

  const imageFields = [];
  const imageFieldsTranslatable = [];

  // eslint-disable-next-line no-undef
  if (realResource.toLocaleLowerCase() === 'setting' && params.data.value?.rawFile instanceof window.Blob) {
    return {
      value: {
        id: await convertFileToBase64(params.data.value.rawFile),
      },
    };
  }

  Object.keys(dataProperties || {}).forEach((key) => {
    const refField = dataProperties[key].$ref || dataProperties[key].allOf?.filter((i) => i?.$ref)?.[0]?.$ref;
    if (refField?.endsWith('Blob')) {
      if (dataProperties[key]?.properties?.translatable?.default) {
        imageFieldsTranslatable.push(key);
      } else {
        imageFields.push(key);
      }
    }
  });

  if (imageFields?.length === 0 && imageFieldsTranslatable.length === 0) {
    return {};
  }

  const base64Pictures = await Promise.all(
    imageFields
      .filter((fieldName) => params.data[fieldName]?.rawFile instanceof window.File)
      .map(async (fieldName) => ({
        key: fieldName,
        value: await handleCompressImage(params.data[fieldName]?.rawFile),
      })),
  );

  const dto = base64Pictures.reduce((obj, picture) => {
    obj[picture.key] = {
      id: picture.value,
    };
    return obj;
  }, {});

  if (imageFieldsTranslatable.length) {
    imageFieldsTranslatable.forEach((key) => {
      dto[key] = {
        ...params.data[key],
      };
    });

    const flattenDeepImageFieldsTranslatable = flattenDeep(
      imageFieldsTranslatable.map((fieldName) =>
        Object.keys(params.data[fieldName] || {}).map((locale) => ({
          key: `${fieldName}.${locale}.id`,
          value: params.data[fieldName]?.[locale]?.rawFile,
          fieldName: `${fieldName}.${locale}`,
        })),
      ),
    );

    const imageFieldsTmp = await Promise.all(
      flattenDeepImageFieldsTranslatable
        .filter((image) => image.value instanceof window.File)
        .map(async (image) => ({
          key: image.key,
          value: await handleCompressImage(image.value),
          fieldName: image.fieldName,
        })),
    );

    imageFieldsTmp.forEach((image) => {
      set(dto, image.fieldName, null);
      set(dto, image.key, image.value);
    });
  }

  return dto;
};

const enhancedDataProvider = {
  ...crudProvider('/api'),
  create: async (resource, params) => {
    const blobData = await parseBase64Data(resource, params);

    return crudProvider('/api').create(resource, {
      ...params,
      data: {
        ...params.data,
        ...blobData, // overide blob fields with blob data
      },
    });
  },
  update: async (resource, params) => {
    const blobData = await parseBase64Data(resource, params);
    return crudProvider('/api').update(resource, {
      ...params,
      data: {
        ...params.data,
        ...blobData,
      },
    });
  },
  // Write custom handlers here
  // https://marmelab.com/react-admin/DataProviders.html#extending-a-data-provider-example-of-file-upload
};

export default enhancedDataProvider;
