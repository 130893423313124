import { Edit, useEditController, useTranslate } from 'react-admin';
import React from 'react';
import { Box } from '@material-ui/core';
import resourceSlug from '../../constant/resource-slug';
import { useUpdateCustom } from '../../base/hooks';
import { formatCheckInData, parseCheckInData, useCheckInNavigation } from './components/utils';
import Actions from '../../base/components/ra/views/action-toolbar';
import CheckInCreateUpdate from './components/CheckInCreateEdit';

const CampaignUpdate = (props) => {
  const t = useTranslate();
  const { record } = useEditController(props);

  useCheckInNavigation();

  const { update, updateLoading: isUpdating } = useUpdateCustom({
    resource: resourceSlug.CHECK_IN,
    successMessage: 'resources.check-in.updated-successfully',
    resourceTranslated: t(`resources.${resourceSlug.CHECK_IN}.name`),
  });

  const handleSave = (data) => {
    update(resourceSlug.CHECK_IN, record.id, parseCheckInData(data));
  };

  return (
    <Box
      component={Edit}
      {...props}
      width="90%"
      maxWidth={800}
      mx="auto"
      actions={<Actions data={record} resource={resourceSlug.CHECK_IN} hasList hasShow />}
    >
      <CheckInCreateUpdate
        onSave={handleSave}
        isSaving={isUpdating}
        uneditableFields={record?.uneditableFields}
        initialValues={formatCheckInData(record)}
      />
    </Box>
  );
};

export default CampaignUpdate;
