import React from 'react';
import { minValue, required, useTranslate } from 'react-admin';
import { IntegerInput } from '../../../../base/components/ra/inputs';

const FreeSpinQuantityInput = () => {
  const t = useTranslate();

  return (
    <IntegerInput
      source="freeSpinQuantity"
      label="resources.reward.fields.free-spins-count"
      fullWidth
      isRequired
      validate={[
        required(),
        minValue(
          1,
          t('ra.validation.greater', {
            min: 0,
          }),
        ),
      ]}
      resettable
    />
  );
};

export default FreeSpinQuantityInput;
