import { useEffect } from 'react';
import { useTranslate } from 'ra-core';
import { useNotify, useCreate } from 'react-admin';
import { useHistory } from 'react-router-dom';
import useError from './useError';

const useCreateCustom = ({ resource, resourceTranslated, redirectTo = 'show', successMessage }) => {
  const translate = useTranslate();
  const notify = useNotify();
  const history = useHistory();
  const { notifyError } = useError();

  const handleCreateSuccess = (response) => {
    const { data } = response;

    notify(
      successMessage
        ? translate(successMessage)
        : translate('ra.notification.created', {
            smart_name: translate(resourceTranslated),
          }),
      'success',
    );

    if (redirectTo === 'show') {
      history.replace(`/${resource}/${data?.id}/${redirectTo}`);
    } else if (redirectTo === 'list') {
      history.replace(`/${resource}`);
    }
  };

  const [create, { loading: createLoading, error }] = useCreate(undefined, undefined, {
    onSuccess: handleCreateSuccess,
  });

  useEffect(() => {
    if (!createLoading && error) {
      notifyError(error);
    }
  }, [createLoading, error]);

  return {
    create,
    createLoading,
    error,
  };
};

export default useCreateCustom;
