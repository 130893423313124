import { REWARD_TYPES } from '../../../constant/reward';

export const formatRewardData = (data) => ({
  currencyId: data?.currencyId,
  rewardType: data?.rewardType,
  rewardName: data?.rewardName,
  isEnabled: !!data?.isEnabled,
  validPeriod: data?.validPeriod,
  validPeriodUnit: data?.validPeriodUnit,
  turnover: data?.turnoverPercent / 100,
  gameIds: data?.gameIds,
  betAmount: data?.betAmount,
  cashAmount: data?.amount,
  freeSpinQuantity: data?.freeSpinQuantity,
  maxWin: data?.maxWin,
});

export const parseRewardData = (data) => ({
  currencyId: data?.currencyId,
  rewardType: data?.rewardType,
  rewardName: data?.rewardName,
  isEnabled: !!data?.isEnabled,
  validPeriod: data?.validPeriod,
  validPeriodUnit: data?.validPeriodUnit,
  turnoverPercent: data?.turnover * 100,
  ...(data?.rewardType === REWARD_TYPES.CASH_VOUCHER && {
    cashVoucherReward: {
      amount: data?.cashAmount,
    },
  }),
  ...([REWARD_TYPES.FREE_SPIN, REWARD_TYPES.RUBY_CARD, REWARD_TYPES.GOLD_CARD].includes(data?.rewardType) && {
    freeSpinReward: {
      maxWin: data?.maxWin,
      gameIds: data?.gameIds?.map((game) => game.value || game),
      freeSpinQuantity: data?.freeSpinQuantity,
      betAmount: data?.betAmount,
    },
  }),
});
