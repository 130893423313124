export const CONVERSION_TRANSACTION_STATUS = {
  PENDING: 'PENDING',
  FAILED: 'FAILED',
  COMPLETED: 'COMPLETED',
};

export const CONVERSION_TRANSFER_TYPE = {
  TRANSFER_IN: 'TRANSFER_IN',
  TRANSFER_OUT: 'TRANSFER_OUT',
};
