import React from 'react';
import { required, useTranslate } from 'react-admin';
import { REWARD_TYPES } from '../../../../constant/reward';
import SelectInput from '../../../../base/components/ra/inputs/SelectInput';

const RewardTypeSelect = (props) => {
  const t = useTranslate();

  return (
    <SelectInput
      source="rewardType"
      label="resources.reward.fields.reward-type"
      choices={Object.values(REWARD_TYPES).map((type) => ({
        id: type,
        name: t(`resources.reward.reward-type.${type}`),
      }))}
      isRequired
      validate={required(t('ra.validation.required'))}
      {...props}
    />
  );
};

export default RewardTypeSelect;
