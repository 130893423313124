import { getHook } from '../util/react-hooks-outside';
import { updateRequireChangePassword } from '../redux/auth/auth.action';

export const handleRequireChangePassword = () => {
  const dispatch = getHook('useDispatch');
  dispatch(
    updateRequireChangePassword({
      needChange: true,
    }),
  );
};

export const handleNotifyErrorOutside = (error) => {
  const notifyErrorInstant = getHook('useError');

  if (notifyErrorInstant && typeof notifyErrorInstant?.notifyError === 'function') {
    notifyErrorInstant.notifyError(error);
  }
};
