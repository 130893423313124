import {
  // AutocompleteInput as RaAutocompleteInput,
  TextInput as RaTextInput,
  NumberInput as RaNumberInput,
  PasswordInput as RaPasswordInput,
  DateInput as RaDateInput,
  DateTimeInput as RaDateTimeInput,
  SelectArrayInput as RaSelectArrayInput,
  NullableBooleanInput as RaNullableBooleanInput,
} from 'react-admin';
import { withStandardInputProps } from '../../../hoc/with-standard-input-props';
import RaAutocompleteArrayInputCustom from './AutocompleteArrayInput';
import RaAutocompleteInputCustom from './AutocompleteInput';
import RaSelectInput from './SelectInput';

export const AutocompleteInput = withStandardInputProps(RaAutocompleteInputCustom);
// export const AutocompleteInput = withStandardInputProps(RaAutocompleteInput);
export const AutocompleteArrayInput = withStandardInputProps(RaAutocompleteArrayInputCustom);
export const TextInput = withStandardInputProps(RaTextInput);
export const NumberInputRA = withStandardInputProps(RaNumberInput);
export const PasswordInput = withStandardInputProps(RaPasswordInput);
export const DateInput = withStandardInputProps(RaDateInput);
export const DateTimeInput = withStandardInputProps(RaDateTimeInput);
export const SelectInput = withStandardInputProps(RaSelectInput);
export const SelectArrayInput = withStandardInputProps(RaSelectArrayInput);
export const NullableBooleanInput = withStandardInputProps(RaNullableBooleanInput);

export { default as TuiEditorInput } from './tuiEditor.input';
export { default as BooleanInput } from './boolean.input';
export { default as Checkbox } from './checkbox.input';
export { default as PrizeTableInput } from './prizeTable.input';
export { default as CheckBoxGroup } from './checkBoxGroup.input';
export { default as IntegerInput } from './integer.input';
export { default as JsonRawInput } from './jsonRawInput.input';
export { default as JsonInput } from './json.input';
export { default as ReferenceInput } from './reference.input';
export { default as DateQuickRange } from './dateQuickRange.input';
export { default as NumberRangeInput } from './numberRange.input';
export { default as MinMaxBound } from './minMaxBound.input';
export { default as TranslatableInput } from './translatable.input';
export { default as NumberInput } from './number.input';
