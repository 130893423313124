import {
  CACHE_CHECK_INS_BY_PAGE,
  UPDATE_CURRENT_CHECK_IN_PAGE,
  CACHE_CHECK_IN_PLAYER_REWARDS_BY_PAGE,
  UPDATE_CURRENT_CHECK_IN_PLAYER_REWARD_PAGE,
} from '../root.actions';

export const cacheCheckInsByPage = ({ data, page }) => ({
  type: CACHE_CHECK_INS_BY_PAGE,
  payload: {
    data,
    page,
  },
});

export const updateCurrentCheckInPage = (data) => ({
  type: UPDATE_CURRENT_CHECK_IN_PAGE,
  payload: data,
});

export const cacheCheckInPlayerRewardsByPage = ({ data, page }) => ({
  type: CACHE_CHECK_IN_PLAYER_REWARDS_BY_PAGE,
  payload: {
    data,
    page,
  },
});

export const updateCurrentCheckInPlayerRewardPage = (data) => ({
  type: UPDATE_CURRENT_CHECK_IN_PLAYER_REWARD_PAGE,
  payload: data,
});
