import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { FunctionField, TextField, SelectField, useTranslate } from 'react-admin';
import { Add, Close, Search } from '@material-ui/icons';
import keyBy from 'lodash/keyBy';
import { useSelector } from 'react-redux';
import CustomizedList from '../../../../../../../base/components/customized/CustomizedList';
import resourceSlug from '../../../../../../../constant/resource-slug';
import CustomizedFilterWrapper from '../../../../../../../base/components/customized/CustomizedFilterWrapper';
import { SelectInput, TextInput } from '../../../../../../../base/components/ra/inputs';
import { REWARD_TYPES } from '../../../../../../../constant/reward';
import { useCheckInForm, useSetSelectedRecords } from '../../../../utils';
import RewardPagination from '../../../../../../reward/components/RewardList/RewardPagination';

const AddRewardModal = ({ onChange, defaultRewards }) => {
  const t = useTranslate();
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button startIcon={<Add />} onClick={() => setOpen(true)} className={classes.box}>
        {t('ra.action.add')}
      </Button>
      {open && <RewardModal open onClose={() => setOpen(false)} onChange={onChange} defaultRewards={defaultRewards} />}
    </>
  );
};

export default AddRewardModal;

const RewardModal = ({ open, onClose, onChange, defaultRewards }) => {
  const t = useTranslate();
  const classes = useStyles();
  const { data } = useCheckInForm();
  const { currencyId } = data.current;
  const [selectedIds, setSelectedIds] = useState(defaultRewards?.map?.((reward) => reward.rewardId) || []);
  const { rewardList, currentRewardPage } = useSelector(({ reward }) => reward);

  const { clearSelection, select } = useSetSelectedRecords(resourceSlug.REWARD);

  const rewards = useMemo(() => rewardList[currentRewardPage]?.data || [], [rewardList, currentRewardPage]);

  const handleSave = () => {
    const allRewards = keyBy(
      Object.values(rewardList).reduce((acc, current) => acc.concat(current.data), []),
      'id',
    );
    onChange(
      selectedIds.map((id) => ({
        rewardId: id,
        rewardName: allRewards[id].rewardName,
      })),
    );
    setSelectedIds([]);
    clearSelection();
    onClose();
  };

  useEffect(() => {
    if (open) {
      select(defaultRewards?.map?.((reward) => reward.rewardId) || []);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      classes={{
        paper: classes.paper,
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" px={6} py={1}>
        <Typography variant="h3">{t('resources.reward.name')}</Typography>

        <IconButton onClick={() => onClose()}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent dividers>
        <Box
          component={CustomizedList}
          sx={{
            "& [class*='MuiTableContainer']": {
              maxHeight: '300px !important',
            },
            "& [class*='RaListToolbar-actions']": {
              width: '100%',
            },
          }}
          resource={resourceSlug.REWARD}
          basePath="/reward"
          disabledAutoRefetch
          hasList
          hasCreate={false}
          filter={{
            isEnabled: true,
            currencyId,
          }}
          filters={<RewardFilterWrapper />}
          bulkActionButtons={<BulkActionButton onChange={setSelectedIds} />}
          controllerProps={{
            data: keyBy(rewards, 'id'),
            ids: rewards?.map(({ id }) => id),
          }}
          pagination={<RewardPagination />}
          exporter={false}
          paginationProps={{
            rowsPerPageOptions: [25],
          }}
        >
          <TextField source="rewardName" label="resources.reward.fields.name" sortable={false} />
          <SelectField
            source="rewardType"
            label="resources.reward.fields.reward-type"
            choices={Object.values(REWARD_TYPES).map((type) => ({
              id: type,
              name: t(`resources.reward.reward-type.${type}`),
            }))}
            sortable={false}
          />
          <FunctionField
            label="resources.reward.fields.expiry"
            render={(record) =>
              t(`resources.reward.expiry.${record.validPeriodUnit}`, {
                smart_count: record.validPeriod,
              })
            }
          />
        </Box>
      </DialogContent>
      <Box
        component={DialogActions}
        sx={{
          padding: '8px 24px !important',
        }}
      >
        <Button variant="contained" onClick={handleSave} disableElevation>
          {t('ra.action.add')}
        </Button>
      </Box>
    </Dialog>
  );
};

const RewardFilterWrapper = () => {
  const t = useTranslate();
  const classes = useStyles();

  const rewardTypeChoices = useMemo(
    () =>
      Object.values(REWARD_TYPES).map((type) => ({
        id: type,
        name: t(`resources.reward.reward-type.${type}`),
      })),
    [t],
  );

  return (
    <CustomizedFilterWrapper
      searchButton={
        <IconButton color="primary" className={classes.searchButton}>
          <Search />
        </IconButton>
      }
      className={classes.filterWrapper}
    >
      <TextInput source="rewardName||$cont" label="resources.reward.fields.reward-name" resettable helperText={false} />
      <SelectInput
        source="rewardType"
        label="resources.reward.fields.reward-type"
        choices={rewardTypeChoices}
        helperText={false}
        resettable
      />
    </CustomizedFilterWrapper>
  );
};

const BulkActionButton = ({ selectedIds, onChange }) => {
  useEffect(() => {
    onChange?.(selectedIds);
  }, [selectedIds]);

  return null;
};

const useStyles = makeStyles((theme) => ({
  box: {
    width: 120,
    aspectRatio: 1,
    border: `1px dashed ${theme.palette.grey[300]}`,
    borderRadius: 8,
    flexDirection: 'column',
    margin: 0,
  },
  paper: {
    minWidth: 600,
    maxWidth: 900,
    width: '60%',
    maxHeight: '60vh',
    height: 'fit-content',
  },
  filterWrapper: {
    '& form': {
      flexDirection: 'row',
      alignItems: 'center',
      "& [class*='Grid']": {
        width: 'unset',
      },
    },
  },
  searchButton: {
    backgroundColor: `${theme.palette.grey[300]} !important`,
    borderRadius: 8,
    marginTop: -8,
    padding: '8px !important',
  },
}));
