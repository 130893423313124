import React, { useMemo } from 'react';
import { useRedirect, useTranslate } from 'react-admin';
import { useParams, useLocation } from 'react-router-dom';
import { Box } from '@material-ui/core';
import resourceSlug from '../../../../constant/resource-slug';
import MuiDrawer from '../../../../base/components/mui/MuiDrawer';
import MuiTabs from '../../../../base/components/mui/MuiTabs';
import MuiTab from '../../../../base/components/mui/MuiTab';
import DetailsTab from './DetailsTab';
import ChangeLogTab from './ChangeLogTab';

const RewardDetailsDrawer = ({ open }) => {
  const t = useTranslate();
  const { search, pathname } = useLocation();
  const redirect = useRedirect();
  const { id } = useParams();

  const onClose = () => {
    redirect(`/${resourceSlug.REWARD}${search}`);
  };

  const currentTab = useMemo(() => {
    if (pathname.includes('details')) return 'details';
    return 'changeLogs';
  }, [pathname]);

  return (
    <MuiDrawer open={open} anchor="right" onClose={onClose} title={t('resources.reward.reward-details')}>
      {open ? (
        <>
          <MuiTabs value={currentTab}>
            <Box
              component={MuiTab}
              sx={{
                flexGrow: 1,
                maxWidth: 'unset',
              }}
              value="details"
              label={t('resources.reward.details')}
              onClick={() => redirect(`/${resourceSlug.REWARD}/${id}/details/${search}`)}
            />
            <Box
              component={MuiTab}
              sx={{
                flexGrow: 1,
                maxWidth: 'unset',
              }}
              value="changeLogs"
              label={t('resources.reward.change-logs')}
              onClick={() => redirect(`/${resourceSlug.REWARD}/${id}/change-log/${search}`)}
            />
          </MuiTabs>
          {currentTab === 'details' && <DetailsTab />}
          {currentTab === 'changeLogs' && <ChangeLogTab />}
        </>
      ) : null}
    </MuiDrawer>
  );
};

export default RewardDetailsDrawer;
