import * as React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { formatMoney } from '../../../../services/util/formatNumber';

const AmountField = (props) => {
  const { record, source, symbol } = props;

  const amount = get(record, source);

  return <span>{formatMoney(amount, symbol)}</span>;
};

AmountField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  symbol: PropTypes.string,
};
AmountField.defaultProps = {
  record: {},
};

export default React.memo(AmountField);
