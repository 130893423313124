/* eslint-disable import/no-cycle, no-use-before-define  */
import React, { useMemo } from 'react';
import { FunctionField, ReferenceField, SelectField, TextField, useTranslate } from 'react-admin';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Chip, useTheme } from '@material-ui/core';
import keyBy from 'lodash/keyBy';
import { useTableListStyles } from '../../../../mkt-free-spin/components/utils';
import CommonWrapper from '../../../../../base/components/reverse-integration/common-wrapper';
import CustomizedList from '../../../../../base/components/customized/CustomizedList';
import resourceSlug from '../../../../../constant/resource-slug';
import { AmountField, DateField } from '../../../../../base/components/ra/fields';
import { CONVERSION_TRANSACTION_STATUS, CONVERSION_TRANSFER_TYPE } from '../../../../../constant/player';
import ConversionHistoryPagination from './ConversionHistoryPagination';
import ConversionHistoryAction from './ConversionHistoryAction';
import ConversionHistoryFilters from './ConversionHistoryFilters';

const ConversionHistoryList = (props) => {
  const t = useTranslate();
  const classes = useTableListStyles();
  const { id } = useParams();

  const [conversionHistory, currentConversionHistoryPage] = useSelector(({ conversionHistory }) => [
    conversionHistory.conversionHistoryList,
    conversionHistory.currentConversionHistoryPage,
  ]);

  const conversionTransaction = useMemo(
    () => conversionHistory[currentConversionHistoryPage]?.data || [],
    [conversionHistory, currentConversionHistoryPage],
  );

  return (
    <Box mt={3} component={CommonWrapper} className={classes.wrapper}>
      <CustomizedList
        {...props}
        resource={`${resourceSlug.PLAYER}/${id}/wallet-conversion-history`}
        basePath={`/${resourceSlug.PLAYER}/${id}/show`}
        actions={<ConversionHistoryAction />}
        filters={<ConversionHistoryFilters />}
        hasList
        hasCreate={false}
        hasEdit={false}
        hasShow={false}
        exporter={false}
        className={classes.root}
        pagination={<ConversionHistoryPagination />}
        controllerProps={{
          data: keyBy(conversionTransaction, 'id'),
          ids: conversionTransaction?.map(({ id }) => id),
        }}
        sort={{
          field: 'created',
          order: 'DESC',
        }}
      >
        <TextField source="campaignCode" label="resources.campaign.field.campaign-code" />
        <DateField source="created" label="common.fields.created" sortable={false} />
        <DateField source="updated" label="common.fields.updated" sortable={false} />
        <SelectField
          source="transferType"
          label="resources.players.field.transfer-type"
          sortable={false}
          choices={Object.values(CONVERSION_TRANSFER_TYPE).map((type) => ({
            id: type,
            name: t(`resources.players.transfer-type.${type}`),
          }))}
        />
        <ReferenceField reference="currency" source="currencyId" label="common.field.currencyCode" link={false}>
          <TextField source="code" />
        </ReferenceField>
        <AmountField source="amount" label="common.field.amount" link={false} />
        <AmountField source="balanceBefore" label="resources.players.field.pre-conversion" link={false} />
        <AmountField source="balanceAfter" label="resources.players.field.post-conversion" link={false} />
        <FunctionField label="common.fields.status" render={(record) => <TransactionStatus status={record.status} />} />
      </CustomizedList>
    </Box>
  );
};

const TransactionStatus = ({ status }) => {
  const t = useTranslate();
  const theme = useTheme();

  return (
    <Box
      component={Chip}
      sx={{
        "&[class*='Chip']": {
          borderRadius: 6,
          padding: '3px 12px',
          backgroundColor: {
            [CONVERSION_TRANSACTION_STATUS.COMPLETED]: theme.palette.success.main,
            [CONVERSION_TRANSACTION_STATUS.FAILED]: theme.palette.error.main,
            [CONVERSION_TRANSACTION_STATUS.PENDING]: theme.palette.primary.main,
          }[status],
          color: 'white',
          '& span': {
            padding: '0 !important', // To override default styles
          },
        },
      }}
      label={t(`resources.players.conversion-status.${status}`)}
    />
  );
};

export default ConversionHistoryList;
