export function getCurrencySymbol(locale, currency) {
  if (!currency) {
    return null;
  }

  return (0)
    .toLocaleString(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 10,
    })
    .replace(/\d/g, '')
    .trim();
}

const formatPositionNumber = (locale, symbol, number = '', isPrefix) => {
  const currencySymbol = getCurrencySymbol(locale, symbol);
  if (!isPrefix) {
    return `${number.replace(currencySymbol, '').trim()}${currencySymbol ? ` ${currencySymbol}` : ''}`;
  }

  return `${currencySymbol ? `${currencySymbol} ` : ''}${number.replace(currencySymbol, '').trim()}`;
};

export const formatNumber = (locale, number, symbol, decimals, isPrefix) => {
  if (!locale || Number.isNaN(number)) return null;

  if (decimals) {
    return formatPositionNumber(
      locale,
      symbol,
      Intl.NumberFormat(locale, {
        maximumFractionDigits: decimals.max ?? 2,
        minimumFractionDigits: decimals.min ?? 2,
        currency: symbol,
        style: 'currency',
      }).format(number),
      isPrefix,
    );
  }

  if (symbol) {
    return formatPositionNumber(
      locale,
      symbol,
      new Intl.NumberFormat(locale, {
        currency: symbol,
        style: 'currency',
      }).format(number),
      isPrefix,
    );
  }

  return new Intl.NumberFormat(locale).format(number);
};

export const limitWithRange = (value, [min, max] = []) => {
  if ([min, max].includes(undefined)) {
    return value;
  }

  let correctNumber = value;
  correctNumber = Math.max(correctNumber, min);
  correctNumber = Math.min(correctNumber, max);

  return correctNumber;
};

export const getNumberOnlyTwoDigitsAfter = (number) => parseFloat(number.toFixed(2));

export const convertMbToByte = (mbValue) => 1024 ** 2 * mbValue;

export const roundToTwo = (num) => Math.round((num + Number.EPSILON) * 100) / 100;

export const formatMoney = (amount, currencySymbol, options) => {
  let money = Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...options,
  }).format(amount);

  if (currencySymbol) {
    money = `${currencySymbol} ${money}`;
  }

  return money;
};
