import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    table: {
      tableLayout: 'auto',
      "& [class*='TableCell']:last-child": {
        paddingRight: 8,
      },
    },
    thead: {},
    tbody: {},
    headerRow: {},
    headerCell: {
      position: 'sticky',
      top: 0,
      zIndex: 2,
      cursor: 'pointer',
      backgroundColor: theme.palette.background.paper,
      '&:first-child': {
        borderTopLeftRadius: 8,
      },
      '&:last-child': {
        borderTopRightRadius: 8,
      },
      // whiteSpace: 'nowrap',
      '& span span': {
        display: '-webkit-box',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        wordWrap: 'break-word',
        lineHeight: '24px',
        maxHeight: '48px',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
      },
    },
    checkbox: {},
    row: {},
    clickableRow: {
      cursor: 'pointer',
    },
    rowCell: {},
    expandHeader: {
      padding: 0,
      width: theme.spacing(6),
    },
    expandIconCell: {
      width: theme.spacing(6),
    },
    expandIcon: {
      padding: theme.spacing(1),
      transform: 'rotate(-90deg)',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expanded: {
      transform: 'rotate(0deg)',
    },
    disableSort: {
      cursor: 'default',
      '& > span': {
        cursor: 'default',
      },
    },
  }),
  {
    name: 'RaDatagrid',
  },
);

export default useStyles;
