import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactJson from 'react-json-view';
import {
  FunctionField,
  RecordContext,
  RecordContextProvider,
  TextField,
  useDataProvider,
  useTranslate,
} from 'react-admin';
import { Box, makeStyles } from '@material-ui/core';
import resourceSlug from '../../../../constant/resource-slug';
import { DateField } from '../../../../base/components/ra/fields';
import ArrayWithPaginationField from '../../../../base/components/ra/fields/arrayWithPagination.field';
import MuiDrawer from '../../../../base/components/mui/MuiDrawer';
import { ShowSection } from '../../../../base/components/guesser/wealth-show.guesser';
import Labeled from '../../../../base/components/ra/labeled';

const ChangeLogTab = () => {
  const { id } = useParams();
  const dataProvider = useDataProvider();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState();

  const handleFetchChangeLog = async (id) => {
    try {
      const { data } = await dataProvider.getCustomList(`${resourceSlug.REWARD}/${id}/change-log`);

      setData({
        data: data?.data?.map((item, idx) => ({
          id: idx,
          ...item,
        })),
      });
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!id) return;
    handleFetchChangeLog(id);
  }, [id]);

  return (
    <>
      <Box
        sx={{
          mt: 6,
          width: '26rem',
          flexGrow: 1,
          overflow: 'hidden',
          '&>*:first-child': {
            height: 'fit-content',
            "& *[class*='MuiTableContainer']": {
              maxHeight: 'calc(100% - 55px) !important',
            },
          },
        }}
      >
        <RecordContextProvider value={data}>
          <ArrayWithPaginationField
            source="data"
            label={false}
            loading={loading}
            loaded={!!data}
            rowClick={(_, __, record) => setDetails(record)}
          >
            <DateField source="created" label="resources.reward.fields.created" sortable={false} />
            <TextField source="createdBy" label="resources.reward.fields.actor" />
            <TextField source="action" label="resources.reward.fields.action" sortable={false} />
          </ArrayWithPaginationField>
        </RecordContextProvider>
      </Box>

      <ChangeLogDetailDrawer data={details} onClose={() => setDetails(undefined)} open={!!details} />
    </>
  );
};

const ChangeLogDetailDrawer = ({ data, onClose, open }) => {
  const t = useTranslate();
  const classes = useStyles();

  return (
    <MuiDrawer
      open={open}
      anchor="right"
      onClose={onClose}
      title={t('resources.reward.change-log-details')}
      classes={{
        paper: classes.paper,
      }}
    >
      <RecordContext.Provider value={data}>
        <ShowSection
          classes={{
            root: classes.row,
          }}
          className={classes.detailsWrapper}
          title={null}
        >
          <Labeled label="resources.reward.fields.created">
            <DateField source="created" />
          </Labeled>
          <TextField source="createdBy" label="resources.reward.fields.actor" />
          <TextField source="action" label="resources.reward.fields.action" sortable={false} />
          <FunctionField
            label="resources.reward.fields.payload"
            render={(record) => (
              <ReactJson src={record.payload} name={null} collapsed enableClipboard displayDataTypes={false} />
            )}
          />
        </ShowSection>
      </RecordContext.Provider>
    </MuiDrawer>
  );
};

const useStyles = makeStyles({
  row: {
    display: 'flex',
    flexDirection: 'column',
    gap: 18,
    '& .ra-field': {
      width: '100%',
    },
  },
  paper: {
    width: '29rem',
  },
  detailsWrapper: {
    padding: 0,
  },
});

export default ChangeLogTab;
