import { CACHE_REWARDS_BY_PAGE, UPDATE_CURRENT_REWARD_PAGE } from '../root.actions';

export const cacheRewardsByPage = ({ data, page }) => ({
  type: CACHE_REWARDS_BY_PAGE,
  payload: {
    data,
    page,
  },
});

export const updateCurrentRewardPage = (data) => ({
  type: UPDATE_CURRENT_REWARD_PAGE,
  payload: data,
});
