import { CACHE_CONVERSION_HISTORY_BY_PAGE, UPDATE_CURRENT_CONVERSION_HISTORY_PAGE } from '../root.actions';

export const cacheConversionHistoryByPage = ({ data, page }) => ({
  type: CACHE_CONVERSION_HISTORY_BY_PAGE,
  payload: {
    data,
    page,
  },
});

export const updateCurrentConversionHistoryPage = (data) => ({
  type: UPDATE_CURRENT_CONVERSION_HISTORY_PAGE,
  payload: data,
});
