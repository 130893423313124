/* eslint-disable no-case-declarations */
import {
  CACHE_CHECK_INS_BY_PAGE,
  UPDATE_CURRENT_CHECK_IN_PAGE,
  CACHE_CHECK_IN_PLAYER_REWARDS_BY_PAGE,
  UPDATE_CURRENT_CHECK_IN_PLAYER_REWARD_PAGE,
} from '../root.actions';

const initialCheckInStates = {
  checkInList: {},
  currentCheckInPage: 1,
  checkInPlayerRewardList: {},
  currentCheckInPlayerRewardPage: 1,
};

export const checkInReducer = (state = initialCheckInStates, action) => {
  const newPage = action.payload?.page || 1;

  switch (action.type) {
    case CACHE_CHECK_INS_BY_PAGE:
      return {
        ...state,
        currentCheckInPage: newPage,
        checkInList:
          newPage === 1
            ? {
                1: action.payload?.data,
              }
            : {
                ...state.checkInList,
                [newPage]: action.payload?.data,
              },
      };
    case UPDATE_CURRENT_CHECK_IN_PAGE:
      return {
        ...state,
        currentCheckInPage: action.payload,
      };
    case CACHE_CHECK_IN_PLAYER_REWARDS_BY_PAGE:
      return {
        ...state,
        currentCheckInPlayerRewardPage: newPage,
        checkInPlayerRewardList:
          newPage === 1
            ? {
                1: action.payload?.data,
              }
            : {
                ...state.checkInPlayerRewardList,
                [newPage]: action.payload?.data,
              },
      };

    case UPDATE_CURRENT_CHECK_IN_PLAYER_REWARD_PAGE:
      return {
        ...state,
        currentCheckInPlayerRewardPage: action.payload,
      };
    default:
      return state;
  }
};
