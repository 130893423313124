/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from 'react';
import { useShowController, useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import WealthShowGuesser from '../../base/components/guesser/wealth-show.guesser';
import ValueLoading from '../../base/components/value-loading';
import PlayerChip from './components/player-chip';
import { ActionMenu } from './components/action.menu';
import PlayerWallets from './components/PlayerWallets';

const useStyles = makeStyles({
  chipLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  loadingRoot: {
    width: '32px',
    marginLeft: '8px',
  },
});

const PlayerShow = (props) => {
  const { record } = useShowController(props);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const loadingTimeout = useRef();
  const t = useTranslate();

  const Loading = () => (
    <ValueLoading
      classes={{
        root: classes.loadingRoot,
      }}
    />
  );

  const renderPlayerChips = () => {
    const totalBet = loading ? <Loading /> : record?.totalBet?.toFixed?.(2);
    const totalWinLoss = loading ? <Loading /> : record?.totalWinLoss?.toFixed?.(2);

    return (
      <>
        <PlayerChip key="totalBet" value={totalBet} text="resources.player.fields.totalBet" />
        <PlayerChip key="totalWinLoss" value={totalWinLoss} text="resources.player.fields.totalWinLoss" />
      </>
    );
  };

  useEffect(() => {
    if ([record?.totalWinLoss, record?.totalBet].includes(undefined)) {
      setLoading(true);
    } else {
      setLoading(false);
    }

    // Drop off loading after 60s
    loadingTimeout.current = setTimeout(() => setLoading(false), 1000 * 30);

    // Clear up
    return () => clearTimeout(loadingTimeout.current);
  }, [record]);

  return (
    <WealthShowGuesser
      {...props}
      contentFooter={<WagerLink />}
      actionMenu={<ActionMenu {...props} />}
      metaDataComponents={[renderPlayerChips()]}
      sections={[<PlayerWallets title={t('resources.player.wallet-info')} />]}
    />
  );
};

const WagerLink = ({ recordId }) => {
  const translate = useTranslate();

  return (
    <a href={`#/wager?displayedFilters=%7B"player.id"%3Atrue%7D&filter=%7B"player"%3A%7B"id"%3A"${recordId}"%7D%7D`}>
      {translate('ra.action.view_wager')}
    </a>
  );
};

export default PlayerShow;
