import React, { useMemo, useRef } from 'react';
import moment from 'moment';
import { range } from 'lodash';
import { useLocale, useTranslate } from 'react-admin';
import { Button } from 'antd';
import { useField } from 'react-final-form';
import DateRangePicker from '../../../../../base/components/ra/inputs/DateRangePicker';
import { DATETIME_DISPLAY_FORMAT } from '../../../../../constant';

const ExpirationTimeInput = ({
  isEditForm, source, label, disabled,
}) => {
  const calenderValues = useRef([]);
  const locale = useLocale();

  const t = useTranslate();
  const {
    input: {
      onChange, value,
    },
  } = useField(source);

  const getDisabledDate = current => {
    if (current == null) {
      return null;
    }

    const startDate = calenderValues.current?.[0] && moment(calenderValues.current[0].format());
    const endDate = calenderValues.current?.[1] && moment(calenderValues.current[1].format());

    const minDate = moment().subtract(1, 'day').endOf('day');

    const currentDate = moment(current.format()).startOf('hour');

    const isOver1YearDuration1 = startDate ? currentDate.diff(startDate, 'years', true) > 1 : false;
    const isOver1YearDuration2 = endDate ? endDate.diff(currentDate, 'years', true) > 1 : false;

    const isNotFromToday = current.isBefore(minDate);

    return isOver1YearDuration1 || isOver1YearDuration2 || isNotFromToday;
  };

  const getDisableTime = current => {
    const isToday = !!current && moment(current).isSame(moment(), 'day');

    if (isToday) {
      return {
        disabledHours: () => range(0, moment().hours() + 1),
        disabledMinutes: () => range(0, 60),
      };
    }

    return {
      disabledHours: () => [],
      disabledMinutes: () => range(0, 60),
    };
  };

  const formatDate = useMemo(() => {
    const dateFormat = DATETIME_DISPLAY_FORMAT;

    if (isEditForm) {
      return dateFormat;
    }

    return [selected => {
      if (!selected) return '';
      if (moment(selected).isSameOrBefore(moment())) {
        return t('common.text.now');
      }
      return moment(selected).format(dateFormat);
    }, dateFormat];
  }, [locale, t, isEditForm]);

  return (
    <DateRangePicker
      label={label}
      disabledDate={getDisabledDate}
      disabledTime={getDisableTime}
      disabled={disabled || [isEditForm, false]}
      timeFormat="HH:mm"
      showTime={{
        showHour: true,
        showMinute: true,
        showSecond: false,
        defaultValue: [moment('00:00', 'HH:mm'), moment('23:59', 'HH:mm')],
      }}
      source={source}
      onCalendarChange={values => {
        calenderValues.current = values;
      }}
      allowClear
      rangeProps={{
        format: formatDate,
      }}
      order={false}
      onPanel
      renderExtraFooter={() => (
        <Button
          className="ant-tag ant-tag-blue"
          onClick={() => onChange([moment().startOf('hour'), value[1]])}
        >
          {t('resources.campaign.start-campaign-now')}
        </Button>
      )}
    />
  );
};

export default ExpirationTimeInput;
