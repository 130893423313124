import React, { useMemo } from 'react';
import { required, useTranslate } from 'react-admin';
import { Box } from '@material-ui/core';
import { useField } from 'react-final-form';
import InputWithTooltipWrapper from '../../../../../../base/components/common/InputWithTooltipWrapper';
import Switcher from '../../../../../../base/components/common/Switcher';
import { CHECK_IN_TYPES, WALLET_TYPES } from '../../../../../../constant/checkIn';
import { SelectInput } from '../../../../../../base/components/ra/inputs';
import RewardConfiguration from './RewardConfiguration';
import { useCheckInForm } from '../../../utils';

const Rewards = () => {
  const t = useTranslate();
  const {
    input: { value: streakRepeatable },
  } = useField('streakRepeatable');
  const {
    input: { value: type },
  } = useField('type');
  const { uneditableFields } = useCheckInForm();

  const walletChoices = useMemo(
    () =>
      Object.values(WALLET_TYPES).map((type) => ({
        id: type,
        name: t(`common.wallet.${type}`),
      })),
    [t],
  );

  return (
    <>
      {type === CHECK_IN_TYPES.CONSECUTIVE && !streakRepeatable && (
        <InputWithTooltipWrapper
          source="rewardRepeatable"
          title={t('resources.check-in.fields.repeatable-reward')}
          tooltip={t('resources.check-in.fields.repeatable-reward-tooltip')}
          disabled={!!uneditableFields?.includes?.('rewardRepeatable')}
        >
          <Box
            component={Switcher}
            defaultValue={false}
            width="fit-content"
            mb={3}
            sx={{
              '& label': {
                flexDirection: 'row-reverse',
                gap: 18,
              },
            }}
          />
        </InputWithTooltipWrapper>
      )}
      <SelectInput
        source="destinationTransferWallet"
        label="resources.check-in.fields.transfer-destination"
        isRequired
        validate={[required()]}
        choices={walletChoices}
        disabled={!!uneditableFields?.includes?.('walletConversion')}
      />
      <RewardConfiguration />
    </>
  );
};

export default Rewards;
