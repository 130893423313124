import React, { useCallback } from 'react';
import { useInput, useTranslate } from 'react-admin';
import { NumericFormat } from 'react-number-format';
import ResettableTextField from '../../../../base/components/ra/inputs/ResettableTextField';

const AmountInput = ({ source, isRequired, label, ...props }) => {
  const t = useTranslate();

  const amountValidate = useCallback(
    (value) => {
      if (value == null) {
        return t('ra.validation.required');
      }

      if (value === 0) {
        return t('ra.validation.greater', {
          min: 0,
        });
      }

      return undefined;
    },
    [t],
  );

  const {
    meta: { error, submitError, touched },
    input: { onChange, value },
  } = useInput({
    source,
    validate: amountValidate,
    parse: (value) => parseFloat(value),
  });

  return (
    <NumericFormat
      customInput={ResettableTextField}
      resettable
      variant="outlined"
      label={isRequired ? `${label} *` : label}
      decimalScale={2}
      thousandSeparator
      onValueChange={(v) => {
        // value without prefix
        onChange(v?.value ? parseFloat(v?.value) : v?.value);
      }}
      onReset={() => {
        onChange();
      }}
      value={value}
      error={!!(touched && (error || submitError))}
      helperText={touched && (error || submitError) ? error || submitError : ' '}
      {...props}
    />
  );
};

export default AmountInput;
