import { Box, MenuItem } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { FunctionField, useRecordContext, useTranslate } from 'react-admin';
import MuiSelect from '../../../../base/components/mui/MuiSelect';
import { ShowSection } from '../../../../base/components/guesser/wealth-show.guesser';
import { formatMoney } from '../../../../services/util/formatNumber';

const BonusWallet = () => {
  const t = useTranslate();
  const record = useRecordContext();

  const [currencyCode, setCurrencyCode] = useState('');

  const currencies = useMemo(
    () =>
      record?.wallets
        ?.filter((wallet) => !!wallet?.bonus)
        ?.map((wallet) => ({
          id: wallet.currencyCode,
          name: wallet.currencyCode,
        })),
    [record],
  );

  const wallet = useMemo(
    () => record?.wallets?.find((wallet) => wallet.currencyCode === currencyCode)?.bonus,
    [currencyCode, record],
  );

  useEffect(() => {
    const firstBonusWallet = record?.wallets?.find((wallet) => !!wallet?.bonus);
    if (firstBonusWallet?.currencyCode) {
      setCurrencyCode(firstBonusWallet?.currencyCode);
    }
  }, [record?.wallets]);

  return (
    <Box
      mt={3}
      display="flex"
      flexDirection="column"
      sx={{
        gap: 12,
      }}
    >
      <Box component={MuiSelect} width={200} value={currencyCode} onChange={(e) => setCurrencyCode(e.target.value)}>
        {currencies?.map((currency) => (
          <MenuItem key={currency.id} value={currency.id}>
            {currency.name}
          </MenuItem>
        ))}
      </Box>
      <Box
        component={ShowSection}
        label={false}
        record={wallet}
        sx={{
          padding: '0 !important',
        }}
      >
        <TextField source="id" label="common.fields.id" />
        <Labeled label={t('common.fields.created')}>
          <DateField source="created" />
        </Labeled>
        <Labeled label={t('common.fields.updated')}>
          <DateField source="updated" />
        </Labeled>
        <Labeled label={t('resources.player.fields.turnover-requirement')}>
          <FunctionField
            render={(record) => `${formatMoney(record.currentTurnover)}/${formatMoney(record.turnoverRequired)}`}
          />
        </Labeled>
      </Box>
    </Box>
  );
};

BonusWallet.propTypes = {};

export default BonusWallet;
