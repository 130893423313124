import React from 'react';
import { Tabs, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    borderBottom: `2px solid ${theme.palette.grey[200]}`,
  },
}));

const MuiTabs = (props) => {
  const classes = useStyles();

  return (
    <Tabs
      {...props}
      classes={{
        flexContainer: clsx(classes.flexContainer, props.classes?.flexContainer),
      }}
    />
  );
};

export default MuiTabs;
