import React from 'react';
import { required } from 'react-admin';
import { AutocompleteInput } from '../../../../base/components/ra/inputs';
import ReferenceInput from '../../../../base/components/ra/inputs/reference.input';
import resourceSlug from '../../../../constant/resource-slug';

const CurrencyInput = () => (
  <ReferenceInput
    source="currencyId"
    isRequired
    resettable
    filter={{
      enabled: true,
    }}
    label="resources.reward.fields.currency"
    reference={resourceSlug.CURRENCY}
    validate={required()}
    filterToQuery={(value) => ({
      'code||$cont': value,
    })}
  >
    <AutocompleteInput optionText="code" />
  </ReferenceInput>
);

export default CurrencyInput;
