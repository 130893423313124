/* eslint-disable no-case-declarations */
import { CACHE_REWARDS_BY_PAGE, UPDATE_CURRENT_REWARD_PAGE } from '../root.actions';

const initialRewardStates = {
  rewardList: {},
  currentRewardPage: 1,
};

export const rewardReducer = (state = initialRewardStates, action) => {
  const newPage = action.payload?.page || 1;

  switch (action.type) {
    case CACHE_REWARDS_BY_PAGE:
      return {
        ...state,
        currentRewardPage: newPage,
        rewardList:
          newPage === 1
            ? {
                1: action.payload?.data,
              }
            : {
                ...state.rewardList,
                [newPage]: action.payload?.data,
              },
      };
    case UPDATE_CURRENT_REWARD_PAGE:
      return {
        ...state,
        currentRewardPage: action.payload,
      };
    default:
      return state;
  }
};
