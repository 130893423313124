/* eslint-disable react/jsx-filename-extension */
import { TablePagination } from '@material-ui/core';
import React from 'react';
import { useListPaginationContext, useTranslate } from 'react-admin';

const Pagination = ({ rowsPerPageOptions }) => {
  const {
    page, perPage, total, setPage, setPerPage,
  } = useListPaginationContext();
  const translate = useTranslate();

  const handlePageChange = (_, newPage) => {
    setPage(newPage + 1);
  };

  const handleRowsPerPageChange = event => {
    setPerPage(event.target.value);
  };

  return (
    <TablePagination
      rowsPerPageOptions={rowsPerPageOptions || [25, 50, 100]}
      component="div"
      count={total || 0}
      rowsPerPage={perPage}
      page={page - 1}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleRowsPerPageChange}
      labelRowsPerPage={translate('ra.navigation.page_rows_per_page')}
    />
  );
};

Pagination.propTypes = {};

Pagination.defaultProps = {};

export default Pagination;
