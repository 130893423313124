import { alpha, Box, FormHelperText, IconButton, makeStyles, Popover, useTheme } from '@material-ui/core';
import MDEditor from '@uiw/react-md-editor';
import React, { useEffect, useMemo, useState } from 'react';
import { useInput } from 'react-admin';
import { Translate } from '@material-ui/icons';
import PropTypes from 'prop-types';
import Fieldset from '../../common/Fieldset';
import { useLanguageList } from '../../../hooks/useLanguageList';
import AutocompleteInput from './AutocompleteInput';

const MultiLangMarkdown = ({
  source,
  value,
  onChange,
  langCode,
  label,
  editorProps,
  error,
  helperText,
  isRequired,
  disabled,
}) => {
  const classes = useStyles();

  const {
    input: { value: currentLanguageCode },
  } = useInput({
    source: `${source}-language`,
    defaultValue: langCode || 'EN',
  });

  const currentLangValue = useMemo(
    () => value?.find?.((item) => item.langCode === currentLanguageCode)?.value || '',
    [currentLanguageCode, value],
  );

  const handleLangValueChange = (content) => {
    if (disabled) return;
    const clonedValue = [...(value || [])];
    const existedId = clonedValue?.findIndex?.((item) => item.langCode === currentLanguageCode);
    const newValue = {
      langCode: currentLanguageCode,
      value: content,
    };
    if (existedId >= 0) {
      clonedValue[existedId] = newValue;
    } else {
      clonedValue.push(newValue);
    }

    onChange(clonedValue);
  };

  return (
    <Box mb={5}>
      <Fieldset
        sx={{
          marginTop: 16,
        }}
        title={
          <MultiLanguageLabel
            source={source}
            label={label}
            defaultLanguageCode={langCode}
            isRequired={isRequired}
            error={error}
            key={`label-${+error}`}
          />
        }
        titleSx={{
          top: -8,
        }}
      >
        <Box
          key={`${source}-${currentLanguageCode}`}
          component={MDEditor}
          value={currentLangValue}
          preview={disabled ? 'preview' : 'edit'}
          onChange={handleLangValueChange}
          mb={8}
          extraCommands={[]}
          highlightEnable={false}
          {...editorProps}
        />
      </Fieldset>
      <Box
        component={FormHelperText}
        key={`helperText-${+error}`}
        className={classes.helperText}
        display={error ? 'block' : 'none'}
        error={error}
      >
        {helperText || ' '}
      </Box>
    </Box>
  );
};

const MultiLanguageLabel = ({ source, label, defaultLanguageCode, isRequired, error }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const theme = useTheme();

  const open = Boolean(anchorEl);

  const { data } = useLanguageList();

  const {
    input: { value: currentLanguageCode, onChange },
  } = useInput({
    source: `${source}-language`,
  });

  const languages = useMemo(
    () =>
      data?.map((item) => ({
        name: item.name,
        id: item.code,
      })),
    [data],
  );

  const languageName = useMemo(
    () => languages?.find((item) => item.id === currentLanguageCode)?.name,
    [languages, currentLanguageCode],
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (defaultLanguageCode && languages?.find((item) => item.id === defaultLanguageCode)) {
      onChange(defaultLanguageCode);
    }
  }, [defaultLanguageCode, languages]);

  return (
    <Box color={error ? theme.palette.error.main : alpha('#000000', 0.6)}>
      <Box component="span" color="inherit">
        {`${label} (${languageName}) ${isRequired ? '*' : ''}`}
      </Box>
      <IconButton size="small" onClick={handleClick} className={classes.translateButton}>
        <Translate />
      </IconButton>
      <Popover
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <AutocompleteInput
          source={`${source}-language`}
          choices={languages}
          label="common.text.language"
          variant="outlined"
          helperText={false}
        />
      </Popover>
    </Box>
  );
};

MultiLangMarkdown.propTypes = {
  source: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      langCode: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  langCode: PropTypes.string,
  label: PropTypes.node,
  error: PropTypes.bool,
  helperText: PropTypes.node,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
};

const useStyles = makeStyles({
  paper: {
    padding: 10,
  },
  translateButton: {
    marginLeft: 4,
    color: 'inherit',
    '& svg': {
      width: 20,
    },
  },
  helperText: {
    marginTop: '-28px !important',
    marginLeft: '14px !important',
  },
});

export default MultiLangMarkdown;
