/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslate } from 'react-admin';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import moment from 'moment';
import { SERVER_DATETIME_FORMAT } from '../../../../constant';

const DATE_RANGE = {
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  THIS_WEEK: 'thisWeek',
  LAST_WEEK: 'lastWeek',
  THIS_MONTH: 'thisMonth',
  LAST_MONTH: 'lastMonth',
};

const startDateFormat = momentDate => momentDate.startOf('second').format(SERVER_DATETIME_FORMAT);
const endDateFormat = momentDate => momentDate.startOf('second').format(SERVER_DATETIME_FORMAT);

export const getPeriodLimitByDateRange = dateRange => {
  switch (dateRange) {
    case DATE_RANGE.YESTERDAY:
      return {
        start: startDateFormat(
          moment().subtract(1, 'days').startOf('day'),
        ),
        end: endDateFormat(
          moment().startOf('day'),
        ),
      };

    case DATE_RANGE.THIS_WEEK:
      return {
        start: startDateFormat(
          moment().startOf('isoweek'),
        ),
        end: endDateFormat(
          moment().endOf('isoweek').add(1, 'day').startOf('day'),
        ),
      };

    case DATE_RANGE.LAST_WEEK:
      return {
        start: startDateFormat(
          moment().subtract(1, 'weeks').startOf('isoweek'),
        ),
        end: endDateFormat(
          moment().startOf('isoweek'),
        ),
      };

    case DATE_RANGE.THIS_MONTH:
      return {
        start: startDateFormat(
          moment().startOf('month'),
        ),
        end: endDateFormat(
          moment().add(1, 'month').startOf('month'),
        ),
      };

    case DATE_RANGE.LAST_MONTH:
      return {
        start: startDateFormat(
          moment().subtract(1, 'month').startOf('month'),
        ),
        end: endDateFormat(
          moment().startOf('month'),
        ),
      };

    case DATE_RANGE.TODAY:
    default:
      return {
        start: startDateFormat(
          moment().startOf('day'),
        ),
        end: endDateFormat(
          moment().add(1, 'days').startOf('day'),
        ),
      };
  }
};

const useStyles = makeStyles(theme => ({
  cardWrap: {
    border: '1px solid #0000003b',
    margin: '16px 0 0',
  },
  label: {
    color: '#00000099',
    fontSize: '16px',
    transform: 'translate(0px, 2px) scale(0.75)',
    transformOrigin: 'left top',
  },
  dateRangeWrap: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
}));

const QuickRange = ({
  form,
  label,
  choices,
}) => {
  const translate = useTranslate();
  const classes = useStyles();

  /**
   * @param {*} rangeType today | yesterday | thisWeek | thisMonth | lastWeek | lastMonth
   */
  const handleChange = rangeType => {
    const {
      start, end,
    } = getPeriodLimitByDateRange(rangeType);

    form.change('created', [start, end].join(','));
  };

  const DateRange = ({
    id, name,
  }) => (
    <Box id={id}>
      <Button onClick={() => handleChange(id)}>
        {translate(name)}
      </Button>
    </Box>
  );

  return (
    <Card
      variant="outlined"
      className={classes.cardWrap}
    >
      <Box padding="2px 14px">
        <Box className={classes.label}>
          {label}
        </Box>
        <Box className={classes.dateRangeWrap}>
          {!!choices?.length && choices.map(item => (
            <DateRange
              key={item.id}
              id={item.id}
              name={item.name}
            />
          ))}
        </Box>
      </Box>
    </Card>
  );
};

QuickRange.propTypes = {
  form: PropTypes.object,
  label: PropTypes.string,
  choices: PropTypes.array,
};

QuickRange.defaultProps = {
  form: {},
  label: '',
  choices: [],
};

export default QuickRange;
