import React, { useCallback } from 'react';
import { useInput, useTranslate } from 'react-admin';
import CountryLanguage from '@ladjs/country-language';
import ctzc from 'country-tz-currency';
import { useSelector } from 'react-redux';
import { useField } from 'react-final-form';
import MultiLangMarkdown from '../../../../../base/components/ra/inputs/MultiLangMarkdown';
import { useCheckInForm } from '../../utils';

const DescriptionInputs = () => {
  const t = useTranslate();
  const {
    input: { value: currencyId },
  } = useField('currencyId');
  const { uneditableFields } = useCheckInForm();
  const currencyCode = useSelector(({ admin }) => admin.resources.currency.data?.[currencyId]?.code);

  const validate = useCallback(
    (value) => {
      if (!value?.find((item) => item.value)) {
        return t('ra.validation.required');
      }
      return undefined;
    },
    [t],
  );

  const {
    meta: { error: termError, touched: termTouched, submitError: termSubmitError },
    input: { onChange: handleTermAndConditionChange, value: termAndCondition },
  } = useInput({
    source: 'termAndCondition',
    validate,
  });

  const showTermError = (termSubmitError || termError) && termTouched;

  const {
    meta: { error: titleError, touched: titleTouched, submitError: titleSubmitError },
    input: { onChange: handleTitleChange, value: title },
  } = useInput({
    source: 'title',
    validate,
  });

  const showTitleError = (titleSubmitError || titleError) && titleTouched;

  return (
    <>
      <MultiLangMarkdown
        value={title}
        onChange={handleTitleChange}
        label={t('resources.check-in.fields.title')}
        langCode={getLanguageCodeByCurrencyCode(currencyCode)}
        isRequired
        error={!!showTitleError}
        helperText={titleError || titleSubmitError}
        disabled={!!uneditableFields?.includes?.('title')}
      />
      <MultiLangMarkdown
        value={termAndCondition}
        onChange={handleTermAndConditionChange}
        label={t('resources.check-in.fields.terms-and-conditions')}
        langCode={getLanguageCodeByCurrencyCode(currencyCode)}
        isRequired
        error={!!showTermError}
        helperText={termError || termSubmitError}
        disabled={!!uneditableFields?.includes?.('termAndCondition')}
      />
    </>
  );
};

const getLanguageCodeByCurrencyCode = (currencyCode, defaultLang = 'EN') => {
  if (!currencyCode) return defaultLang;
  const [countryCode] =
    Object.entries(ctzc.getAllCountries()).find(([, country]) => country.currencyCode === currencyCode) || [];

  if (!countryCode) return defaultLang;

  return CountryLanguage.getCountryLanguages(countryCode, (err, languages) => {
    if (err) {
      return defaultLang;
    }
    return languages[0]?.iso639_1?.toUpperCase() || defaultLang;
  });
};

export default DescriptionInputs;
