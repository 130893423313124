import { Create, useDataProvider, useTranslate } from 'react-admin';
import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import resourceSlug from '../../constant/resource-slug';
import { useCreateCustom, useLoading } from '../../base/hooks';
import { formatCheckInData, parseCheckInData } from './components/utils';
import CheckInCreateUpdate from './components/CheckInCreateEdit';

const CheckInCreate = (props) => {
  const { search } = useLocation();
  const dp = useDataProvider();
  const [clonedRecord, setClonedRecord] = useState();
  const t = useTranslate();
  const { toggleLoading } = useLoading();

  const { create, createLoading: isCreating } = useCreateCustom({
    resource: resourceSlug.CHECK_IN,
    redirectTo: 'list',
    successMessage: 'resources.check-in.created-successfully',
    resourceTranslated: t(`resources.${resourceSlug.CHECK_IN}.name`),
  });

  const handleSave = (values) => {
    create({
      resource: `${resourceSlug.CHECK_IN}/create`,
      payload: {
        data: parseCheckInData(values),
      },
    });
  };

  const handleGetClonedCampaign = async (id) => {
    toggleLoading(true);

    try {
      const { data } = await dp.getOne(resourceSlug.CHECK_IN, {
        id,
      });
      setClonedRecord(formatCheckInData(data, true));
    } catch (error) {
      setClonedRecord({});
    } finally {
      toggleLoading(false);
    }
  };

  useEffect(() => {
    if (!search) return;

    const queryString = search.replace('?', '');
    const queryObject = parse(queryString);
    if (!queryObject.cloneId) return;
    handleGetClonedCampaign(queryObject.cloneId);
  }, [search]);

  return (
    <Box component={Create} {...props} width="90%" maxWidth={800} mx="auto">
      <CheckInCreateUpdate onSave={handleSave} isSaving={isCreating} initialValues={clonedRecord} />
    </Box>
  );
};

export default CheckInCreate;
