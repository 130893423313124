import React from 'react';
import { SelectInput as RaSelectInput } from 'react-admin';

const SelectInput = (props) => (
  <RaSelectInput
    variant="outlined"
    options={{
      SelectProps: {
        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        },
        ...props.options?.SelectProps,
      },
    }}
    {...props}
  />
);

export default SelectInput;
