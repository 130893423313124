/* eslint-disable import/no-cycle, no-use-before-define  */
import React, { useMemo, useState } from 'react';
import {
  RecordContext,
  ReferenceField,
  SelectField,
  TextField,
  useGetOne,
  useLocale,
  useTranslate,
  FunctionField,
} from 'react-admin';
import { keyBy } from 'lodash';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Card, makeStyles, Typography } from '@material-ui/core';
import CustomizedList from '../../../../../base/components/customized/CustomizedList';
import resourceSlug from '../../../../../constant/resource-slug';
import { DateField, NumberField } from '../../../../../base/components/ra/fields';
import { useCheckInNavigation, useTableListStyles } from '../../utils';
import CommonWrapper from '../../../../../base/components/reverse-integration/common-wrapper';
import CheckInPlayerRewardFilters from './CheckInPlayerRewardFilters';
import CheckInPlayerRewardAction from './CheckInPlayerRewardAction';
import CheckInPlayerRewardPagination from './CheckInPlayerRewardPagination';
import { REWARD_TYPES } from '../../../../../constant/reward';
import PlayerRewardStatus from './PlayerRewardStatus';
import CheckInDetailsTabs, { CHECK_IN_TABS } from '../CheckInDetailsTabs';
import DrawerSimpleShowLayout from '../../../../../base/components/ra/drawers/DrawerSimpleShowLayout';
import { WithListContext } from '../../../../../base/components/ra/list/WithListContext';
import { formatNumber } from '../../../../../services/util/formatNumber';
import MuiDrawer from '../../../../../base/components/mui/MuiDrawer';

const CheckInPlayerRewardsList = (props) => {
  const t = useTranslate();
  const classes = useTableListStyles();
  const { id } = useParams();
  const [details, setDetails] = useState();

  useCheckInNavigation();

  const [checkInPlayerRewardList, currentCheckInPlayerRewardPage] = useSelector(({ checkIn }) => [
    checkIn.checkInPlayerRewardList,
    checkIn.currentCheckInPlayerRewardPage,
  ]);

  const playerRewards = useMemo(
    () => checkInPlayerRewardList[currentCheckInPlayerRewardPage]?.data || [],
    [checkInPlayerRewardList, currentCheckInPlayerRewardPage],
  );

  return (
    <Card>
      <CheckInDetailsTabs currentTab={CHECK_IN_TABS.PLAYER_REWARDS}>
        <CommonWrapper className={classes.wrapper}>
          <CustomizedList
            {...props}
            resource={`${resourceSlug.CHECK_IN}/${id}/player-rewards`}
            basePath={`/${resourceSlug.CHECK_IN}/${id}/player-rewards`}
            actions={<CheckInPlayerRewardAction />}
            filters={<CheckInPlayerRewardFilters />}
            syncWithLocation
            hasList
            hasCreate={false}
            hasEdit={false}
            hasShow={false}
            exporter={false}
            className={classes.root}
            pagination={<CheckInPlayerRewardPagination />}
            controllerProps={{
              data: keyBy(playerRewards, 'id'),
              ids: playerRewards?.map(({ id }) => id),
            }}
            rowClick={(_, __, record) => setDetails(record)}
            sort={{
              field: 'issuedDate',
              order: 'DESC',
            }}
          >
            <TextField source="nativeId" label="common.fields.nativeId" />
            <ReferenceField
              reference="group"
              source="groupId"
              label="common.fields.group"
              sortable={false}
              emptyText="-"
              link="show"
            >
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField
              reference="brand"
              source="brandId"
              label="common.fields.brand"
              sortable={false}
              emptyText="-"
              link="show"
            >
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField
              reference="reward"
              source="rewardId"
              label="resources.reward.fields.reward-type"
              sortable={false}
              emptyText="-"
              link={false}
            >
              <SelectField
                source="rewardType"
                choices={Object.values(REWARD_TYPES).map((type) => ({
                  id: type,
                  name: t(`resources.reward.reward-type.${type}`),
                }))}
                sortable={false}
              />
            </ReferenceField>
            <DateField source="issuedDate" label="resources.check-in.fields.issued-date" sortable={false} />
            <DateField source="expiryDate" label="resources.check-in.fields.expiry-date" sortable={false} />
            <DateField
              source="usedDate"
              label="resources.check-in.fields.claimed-date"
              sortable={false}
              emptyText="-"
            />
          </CustomizedList>
        </CommonWrapper>
      </CheckInDetailsTabs>
      <PlayerRewardDetailDrawer data={details} onClose={() => setDetails(undefined)} open={!!details} />
    </Card>
  );
};

const PlayerRewardDetailDrawer = ({ data, onClose, open }) => {
  const t = useTranslate();
  const classes = useStyles();
  const locale = useLocale();

  const { data: reward } = useGetOne(resourceSlug.REWARD, data?.rewardId, {
    enabled: !!data?.rewardId,
  });
  const { data: currency } = useGetOne(resourceSlug.CURRENCY, reward?.currencyId, {
    enabled: !!reward?.currencyId,
  });

  return (
    <MuiDrawer
      open={open}
      anchor="right"
      onClose={onClose}
      title={t('resources.check-in.player-reward-details')}
      classes={{
        paper: classes.paper,
      }}
    >
      <RecordContext.Provider
        value={{
          ...data,
          reward,
        }}
      >
        <Box display="flex" flexDirection="column" height="100%">
          <Box
            component={DrawerSimpleShowLayout}
            sx={{
              flexGrow: 1,
              overflow: 'scroll',
            }}
          >
            <Box
              component={Typography}
              variant="h4"
              sx={{
                marginBottom: '10px !important',
              }}
            >
              {t('resources.check-in.basic-info')}
            </Box>
            <TextField source="nativeId" label="common.fields.nativeId" />
            <ReferenceField reference="group" source="groupId" label="common.fields.group" emptyText="-" link={false}>
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField reference="brand" source="brandId" label="common.fields.brand" emptyText="-" link={false}>
              <TextField source="name" />
            </ReferenceField>
            <FunctionField
              label="resources.check-in.fields.issued-date"
              render={(record) => <DateField record={record} source="issuedDate" emptyText="-" />}
            />
            <FunctionField
              label="resources.check-in.fields.expiry-date"
              render={(record) => <DateField record={record} source="expiryDate" emptyText="-" />}
            />

            <FunctionField
              label="resources.check-in.fields.claimed-date"
              render={(record) => <DateField record={record} source="usedDate" emptyText="-" />}
            />
            <FunctionField
              label="common.fields.status"
              render={(record) => <PlayerRewardStatus status={record.status} />}
            />

            <Box
              component={Typography}
              variant="h4"
              sx={{
                margin: '10px 0 !important',
              }}
            >
              {t('resources.check-in.reward-info')}
            </Box>
            <TextField label="resources.reward.fields.reward-name" source="reward.rewardName" />
            <SelectField
              label="resources.reward.fields.reward-type"
              source="reward.rewardType"
              choices={Object.values(REWARD_TYPES).map((type) => ({
                id: type,
                name: t(`resources.reward.reward-type.${type}`),
              }))}
            />
            <NumberField label="resources.reward.fields.issued-quantity" source="reward.issuedQuantity" emptyText="-" />
            <NumberField label="resources.reward.fields.used-quantity" source="reward.usedQuantity" emptyText="-" />
            {[REWARD_TYPES.FREE_SPIN, REWARD_TYPES.GOLD_CARD, REWARD_TYPES.RUBY_CARD].includes(data?.rewardType) && (
              <ReferenceArrayField
                label="resources.reward.fields.game-list"
                reference={resourceSlug.GAME}
                source="reward.gameIds"
              >
                <WithListContext
                  render={({ data }) =>
                    Object.values(data)
                      .map((game) => game.name?.[locale])
                      .join(', ')
                  }
                />
              </ReferenceArrayField>
            )}
            {[REWARD_TYPES.FREE_SPIN, REWARD_TYPES.GOLD_CARD, REWARD_TYPES.RUBY_CARD].includes(reward?.rewardType) && (
              <NumberField label="resources.reward.fields.free-spins-count" source="reward.freeSpinQuantity" />
            )}
            {[REWARD_TYPES.FREE_SPIN, REWARD_TYPES.GOLD_CARD, REWARD_TYPES.RUBY_CARD].includes(reward?.rewardType) && (
              <FunctionField
                label="resources.reward.fields.bet-amount"
                render={(record) =>
                  record.reward?.betAmount != null
                    ? formatNumber('en', record.reward?.betAmount, currency?.currencyCode)
                    : '-'
                }
              />
            )}
            {[REWARD_TYPES.FREE_SPIN, REWARD_TYPES.GOLD_CARD, REWARD_TYPES.RUBY_CARD].includes(reward?.rewardType) && (
              <FunctionField
                label="resources.reward.fields.max-win"
                render={(record) =>
                  record.reward?.maxWin != null
                    ? formatNumber('en', record.reward?.maxWin, currency?.currencyCode)
                    : '-'
                }
              />
            )}
            {reward?.rewardType === REWARD_TYPES.CASH_VOUCHER && (
              <FunctionField
                label="resources.reward.fields.cash-amount"
                render={(record) =>
                  record.reward?.amount != null
                    ? formatNumber('en', record.reward?.amount, currency?.currencyCode)
                    : '-'
                }
              />
            )}
          </Box>
        </Box>
      </RecordContext.Provider>
    </MuiDrawer>
  );
};

const useStyles = makeStyles({
  paper: {
    width: '29rem',
  },
});

export default CheckInPlayerRewardsList;
