import React from 'react';
import { CreateButton, useTranslate } from 'react-admin';
import CustomizedListActionWrapper from '../../../../base/components/customized/CustomizedListActionWrapper';
import FilterButton from '../../../../base/components/customized/common-list-action-buttons/FilterButton';
import CustomTooltip from '../../../../base/components/custom-tooltip';
import { hasPermission } from '../../../../services/util/auth';
import resourceSlug from '../../../../constant/resource-slug';

const CheckInAction = () => {
  const t = useTranslate();

  return (
    <CustomizedListActionWrapper>
      <FilterButton />
      {hasPermission(resourceSlug.CHECK_IN, 'create') && (
        <CustomTooltip title={t('ra.action.create')} placement="top">
          <CreateButton label="" />
        </CustomTooltip>
      )}
    </CustomizedListActionWrapper>
  );
};

export default CheckInAction;
